/**
 * @classdesc Transfer messages througth all app by subscription.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name DateTableService
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DateTableService {

  private messageSource: BehaviorSubject<any> = new BehaviorSubject(null);

  currentMessage = this.messageSource.asObservable();

  constructor() { }
  /**
   * Update latest message in observable
   * @param message The message to be broadcasted
   */
  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  /**
   * Empty the message source subject
   */
  emptyMessages() {
    this.messageSource = new BehaviorSubject(null);
  }
}
