import { Component, OnInit, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';

// import { Router } from '@angular/router';
@Component({
  selector: 'dialog-confirmation',
  templateUrl: './dialog-confirmation.html',
  styleUrls: ['./dialog-confirmation.scss']
})
export class DialogConfirmation implements OnInit {
  /**
   * The log user keeper
   */
  message: any = {};

  constructor( public dialogRef: MdcDialogRef<DialogConfirmation>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    this.message = data;
  }
  ngOnInit() {
    // this.user = User.getUser();

  }
  validate(): void {
    this.dialogRef.close(true);
  }
  refuse(): void {
    this.dialogRef.close(false);
  }
}
