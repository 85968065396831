import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialog, MdcDialogRef } from "@angular-mdc/web";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { LocalStorage } from "../../helpers/localSortage";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from "../../helpers/loader";
import { User } from '../../helpers/user';

@Component({
  selector: "app-sortie-modal",
  templateUrl: "./sortie-modal.component.html",
  styleUrls: ["./sortie-modal.component.scss"]
})
export class SortieModalComponent implements OnInit {
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  categories_sorties = [];
  charges = [];
  employes = [];
  sortie = {
    id_sortie: "",
    id_charge: "",
    id_categorie_sortie: "",
    id_admin: User.getUserId(),
    libelle_sortie: "",
    description_sortie: "",
    date_sorties: new Date(),
    date_sortie: 0,
    quantite_sortie: 1,
    prix_unitaire: 1,
    prix_total: 1,
    mode: 0
  };
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<SortieModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null) {
      if (data.mode === 1) {
        if (typeof data.date_sortie !== "object") {
          data.date_sorties = new Date(parseInt(data.date_sortie)).getTime();
        } else {
          data.date_sorties = data.date_sortie.getTime();
        }
        this.sortie = data;
      } else {
      }
    }
  }

  ngOnInit() {
    this.service
      .get(ENDPOINTS.get_categories_sortie, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.categories_sorties = data.body;
      });
    this.service
      .get(ENDPOINTS.get_charges_etablissement, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.charges = data.body;
      });
    // this.service
    //   .get(ENDPOINTS.get_institute_employees, {
    //     id_etablissement: this.ets.id_etablissement
    //   })
    //   .then((data: any) => {
    //     this.employes = data.body;
    //     console.log(data);
    //   });
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    var tmp_prod = Object.assign({}, this.sortie);
    console.log(tmp_prod);
    if (typeof tmp_prod.date_sortie === "object") {
      tmp_prod.date_sortie = tmp_prod.date_sorties.getTime();
    } else {
      tmp_prod.date_sortie = new Date(tmp_prod.date_sorties).getTime();
    }
    tmp_prod.prix_total = tmp_prod.prix_unitaire * tmp_prod.quantite_sortie;

    delete tmp_prod.date_sorties;
    // delete tmp_prod.mode;
    Loader.load();
    switch (tmp_prod.mode) {
      case 0:
        delete tmp_prod.id_sortie;
        // <any> tmp_prod.date_sortie;
        delete tmp_prod.mode;

        this.service
          .post(ENDPOINTS.post_sortie_charge, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case 1:
        delete tmp_prod.mode;

        this.service
          .post(ENDPOINTS.post_update_sortie_charge, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        break;
    }
  }
}
