import { Component, Inject, OnInit } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from '@angular-mdc/web';
declare var jQuery: any;

import { Router } from "@angular/router";
import { CorePrototype } from '../../../core/services/core.prototype';
import { ENDPOINTS } from '../../model/endpoints';
import { UNITE_CRITERES } from '../../model/codes';
import { Loader } from '../../helpers/loader';
import { DialogBox } from '../../helpers/dialogBox';
@Component({
  selector: "app-critere-produit-modal",
  templateUrl: "./critere-produit-modal.html",
  styleUrls: ["./critere-produit-modal.scss"],
})
export class CritereProduitModal implements OnInit {
  /**
   * The log user keeper
   */
  message: any = {};
  id_categorie_caree = '2';
  id_produit = '2';
  prod_criteres = [];
  criteres_categ = [];
  critere_produit = {
    nom_critere_produit: "",
    type_critere_produit: "",
    unite_critere_produit: "",
    example_critere_produit: "",
    id_critere_produit: ""
  }
  val_unites = UNITE_CRITERES;
  constructor(
    private route: Router,
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<CritereProduitModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    this.message = data;
    this.critere_produit.nom_critere_produit = data.nom_critere_produit;
    console.log(this.critere_produit);
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
  submit(form): void {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    if (this.critere_produit.id_critere_produit == "") {
      var copy = Object.assign({}, this.critere_produit);
      delete copy.id_critere_produit;
      Loader.loadBack();
      this.service
        .post(ENDPOINTS.post_critere_produit, copy)
        .then((data: any) => {
          console.log(data);
          Loader.stoploadingBack();
          this.dialogRef.close(data.body);

        });
    } else {
      var copy = Object.assign({}, this.critere_produit);
      this.service
        .post(ENDPOINTS.post_update_critere_produit, copy)
        .then((data: any) => {
          console.log(data);
          Loader.stoploadingBack();
        });
    }
  }
  logTab(event) {
    console.log(event);
  }
  showTab(i) {
    jQuery(".tab-body").hide();
    jQuery("#" + i).show();
    // this.showTabArticle("1");
    // jQuery("#tab_prod_ult").click();
  }
}
