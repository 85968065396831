import { Component, OnInit, Inject } from "@angular/core";
import { MdcDialogRef, MdcDialog, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { CorePrototype } from "../../../core/services/core.prototype";
import { LocalStorage } from "../../helpers/localSortage";
import { ENDPOINTS } from "../../model/endpoints";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from "../../helpers/loader";

@Component({
  selector: "app-sortie-service-modal",
  templateUrl: "./sortie-service-modal.component.html",
  styleUrls: ["./sortie-service-modal.component.scss"]
})
export class SortieServiceModalComponent implements OnInit {
  sortie = {
    id_sortie_service: "",
    id_service: "",
    id_categorie_sortie: "",
    id_employe: "1",
    libelle_sortie_service: "",
    description_sortie_service: "",
    date_sorties: new Date(),
    date_sortie_service: 0,
    quantite_sortie_service: 1,
    prix_unitaire_service: 1,
    prix_total_service: 1,
    mode: 0
  };
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  categories_sorties = [];
  services = [];
  employes = [];
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<SortieServiceModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null) {
      if (data.mode === 1) {
        data.date_sorties = new Date(
          parseInt(data.date_sortie_produit)
        ).getTime();
        this.sortie = data;
      } else {
      }
    }
  }

  ngOnInit() {
    this.service
      .get(ENDPOINTS.get_categories_sortie, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.categories_sorties = data.body;
      });
    this.service
      .get(ENDPOINTS.get_min_product_institute, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.services = data.body.services;
      });
    // this.service
    //   .get(ENDPOINTS.get_institute_employees, {
    //     id_etablissement: this.ets.id_etablissement
    //   })
    //   .then((data: any) => {
    //     this.employes = data.body;
    //     console.log(data);
    //   });
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    var tmp_prod = Object.assign({}, this.sortie);
    // tmp_prod.date_sortie_service = tmp_prod.date_sorties.getTime();

    if (typeof tmp_prod.date_sortie_service === "object") {
      tmp_prod.date_sortie_service = tmp_prod.date_sorties.getTime();
    } else {
      tmp_prod.date_sortie_service = new Date(tmp_prod.date_sorties).getTime();
    }
    delete tmp_prod.date_sorties;

    tmp_prod.prix_total_service =
      tmp_prod.prix_unitaire_service * tmp_prod.quantite_sortie_service;

    Loader.load();
    switch (tmp_prod.mode) {
      case 0:
        delete tmp_prod.id_sortie_service;
        // <any> tmp_prod.date_sortie;
        delete tmp_prod.mode;

        this.service
          .post(ENDPOINTS.post_sortie_service, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case 1:
        delete tmp_prod.mode;

        this.service
          .post(ENDPOINTS.post_update_sortie_service, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        break;
    }
  }
}
