import { Component, OnInit, Inject } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from "@angular-mdc/web";
import { Router } from "@angular/router";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { NgForm } from "@angular/forms";
import { Loader } from "../../helpers/loader";
import { STATE } from "../../model/codes";
import { DialogBox } from "../../helpers/dialogBox";

@Component({
  selector: "app-commande-detail-modal",
  templateUrl: "./commande-detail-modal.component.html",
  styleUrls: ["./commande-detail-modal.component.scss"]
})
export class CommandeDetailModalComponent implements OnInit {
  commande: any = {};
  owner: any = {
    nom: "",
    email: "",
    localisation: ""
  };
  showModif = true;
  etat = "";
  serveuse = { nom_employe: "", id_employe: "" };
  constructor(
    private service: CorePrototype,
    private dialog: MdcDialog,
    private route: Router,
    public dialogRef: MdcDialogRef<CommandeDetailModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    data.produits_commanders.forEach(element => {
      element.etat_commande_produit = STATE[element.etat_commande_produit];
    });
    data.services_commanders.forEach(element => {
      element.etat_commande_service = STATE[element.etat_commande_service];
    });
    this.commande = data;
    // this.etat = STATE[data.etat_commande];
    if (this.commande.id_employe != "0") {
      this.service
        .get(ENDPOINTS.get_employee, { id_employe: this.commande.id_employe })
        .then((data: any) => {
          this.serveuse = data.body;
        });
    }

    console.log(data);
  }

  ngOnInit() {
    // this.service.get(ENDPOINTS.get_user, { id_user: this.commande.id_user }).then((user: any) => {
    //   this.owner = user.body;
    // })
    if (
      this.commande.etat_commande == "3" ||
      this.commande.etat_commande == "5"
    ) {
      if (this.commande.id_type_produit) {
        this.showModif = true;
      } else {
        this.showModif = false;
      }
    }
  }
  submit(params: NgForm) {}
  updateCommande(value) {
    this.commande.etat_commande = value;
    // this.commande.firebase_user_token = '';
    console.log(this.commande.type_categorie);
    console.log(value);
    if (this.commande.type_categorie === "1" && value === "2") {
      this.commande.etat_commande = "6";
    }
    if (value === "5" && this.commande.etat_commande === "3") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours"
      });
      return;
    }
    if (value === "5" && this.commande.etat_commande === "6") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours"
      });
      return;
    }
    Loader.load();
    // this.service.post(ENDPOINTS.post_update_order, { firebase_user_token: '', etat_commande: this.commande.etat_commande, id_commande: this.commande.id_commande,commentaire_commande:this.commande.commentaire_commande }).then((user: any) => {
    //   Loader.stopLoading();
    //   console.log(user);
    //   this.dialogRef.close(user.body);
    // })
  }
}
