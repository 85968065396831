
/**
 * @classdesc Helper to manipulate data before HTTP request.
 * @author Melo
 * @version 1.1.0 In this new version, the methods are static so you can use them directly from the class
 * @copyright Seed Developpers 2018
 * @name MethodUtils
 */


export class MethodUtils {
    constructor() {
    }
    /**
     * Convertir un objet en formdata
     * @param object l'objet a inserer dans le formdata
     * @returns Formdata
     */
    toFormData(object: any): FormData {
        let formdata = new FormData;
        // console.log(object.etat);
        for (var prop in object) {
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(prop)) continue;
            // console.log(prop + " = " + object[prop]);
            // console.log(typeof (object[prop]));
            formdata.append(prop, object[prop]);

        }
        return formdata;
    }
    /**
     * Convertir un objet en formdata
     * @param object l'objet a inserer dans le formdata
     * @returns Formdata
     */
    static toFormDatas(object: any): FormData {
        let formdata = new FormData;
        // console.log(object.etat);
        for (var prop in object) {
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(prop)) continue;
            // console.log(prop + " = " + object[prop]);
            // console.log(typeof (object[prop]));
            formdata.append(prop, object[prop]);

        }
        return formdata;
    }
    getFormData(formdata: FormData, object: any): void {
        for (var prop in object) {
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(prop)) continue;
            console.log(formdata.get(prop));
        }
    }
    /**
     * Change object to string to be sent to HTTP request
     * @param object 
     * @returns string
     */
    static toSpecialString(object: any): string {
        let stringToReturn = "";
        for (var prop in object) {
            // console.log(object[prop]);
            // skip loop if the property is from prototype
            if (!object.hasOwnProperty(prop)) continue;
            stringToReturn = '&' + prop + '=' + object[prop] + stringToReturn;
        }
        return stringToReturn;
    }

    /**
     * Validate a mail adresse  
     * @param mail mail to be validated
     * @returns boolean
     */
    ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
            return true;

        return false;
    }

    /**
     * Gets a file and gives a new name to that file
     * @param file File to be renamed
     * @param owner Supplimentary parameter to be used
     * @returns string
     */
    fileRename(file, owner?) {
        var dateVar = new Date().getMilliseconds();
        console.log(typeof (dateVar));
        var hash = function (s) {
            /* Simple hash function. */
            var a = 1, c = 0, h, o;
            if (s) {
                a = 0;
                /*jshint plusplus:false bitwise:false*/
                for (h = s.length - 1; h >= 0; h--) {
                    o = s.charCodeAt(h);
                    a = (a << 6 & 268435455) + o + (o << 14);
                    c = a & 266338304;
                    a = c !== 0 ? a ^ c >> 21 : a;
                }
            }
            return String(a);
        };
        var stringDate = ''
        stringDate = hash(dateVar.toString());
        if (typeof (file) === 'string') {
            return;
        }
        if (file !== null || file !== undefined || file !== "") {
            var extension = file.name;
            extension = extension.split('.').pop();
            var filename = stringDate + "." + extension;
            return filename;

        }

    }
}