import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from './shared/helpers/localSortage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'teslimadmin';
  messages;
  message;
  logged = false;

  constructor(private router: Router) { }

  ngOnInit() {
    if (LocalStorage.getItem('Squares_user') != null) {
      this.logged = true;
    } else {
      this.logged = false;
      this.router.navigate(['/login']);
    }
  }
}
