import { MdcDialog } from "@angular-mdc/web";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CorePrototype } from "src/app/core/services/core.prototype";
import { DialogBox } from "src/app/shared/helpers/dialogBox";
import { Loader } from "src/app/shared/helpers/loader";
import { LocalStorage } from "src/app/shared/helpers/localSortage";
import { User } from "src/app/shared/helpers/user";
import { ENDPOINTS } from "src/app/shared/model/endpoints";
import { EtsModalComponent } from "../ets-modal/ets-modal.component";


@Component({
  selector: "app-etablissements",
  templateUrl: "./etablissements.component.html",
  styleUrls: ["./etablissements.component.scss"]
})
export class EtablissementsComponent implements OnInit {
  entreprises = [];
  user = User.getUser();
  constructor(
    public service: CorePrototype,
    public router: Router,
    public dialog: MdcDialog
  ) { }

  ngOnInit() {
    Loader.loadBack();
    this.service
      .get(ENDPOINTS.get_user_institutes, { id_utilisateur: User.getUserId() })
      .then((data: any) => {
        console.log(data);
        this.entreprises = data.body;
        Loader.stoploadingBack();
      });
  }
  openEts(ets) {
    console.log(ets);
    LocalStorage.setItem("currentEts", JSON.stringify(ets));
    this.router.navigate(["/sales/home"]);
  }
  newEts() {
    const dialogRef = this.dialog.open(EtsModalComponent, {
      data: { mode: "0" }
    });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal !== "close" || resultModal !== null) {
        if (resultModal.id_etablissement) {
          this.entreprises.push(resultModal);
          return;
        }
      }
    });
  }
  edit(ets) {
    ets.mode = "1";
    const dialogRef = this.dialog.open(EtsModalComponent, { data: ets });

    dialogRef.afterClosed().subscribe((resultModal: any) => {
      console.log(resultModal);
      if (resultModal === "close" || resultModal === null) {
        // alert('Closed');
        return;
      }
    });
  }

  delete(ets) {
    DialogBox.confirmationMessage(this.dialog, {
      title: "Confirmation",
      body: "Etes vous sur de vouloir supprimer cet etablissement"
    }).then((result: any) => {
      if (result) {
        this.service
          .post(ENDPOINTS.post_update_institute, {
            id_etablissement: ets.id_etablissement,
            is_deleted_etablissement: "1"
          })
          .then((data: any) => {
            console.log(data);
            var index = this.entreprises.findIndex(
              item => item.id_etablissement == ets.id_etablissement
            );
            this.entreprises.splice(index, 1);
          });
      } else {
      }
    });
  }
  logout() {
    LocalStorage.delete("Squares_user");
    location.reload();
  }
  test() {
    var obj = new FormData();
    var commande1 = {
      id_client: 1,
      prix_total_commande: 10000,
      date_creation_commande: 1579806835797,
      etat_commande: 1,
      is_paid_commande: 1,
      type_commande: 2,
      "commandes[]": []
    };
    var commande2 = {
      id_client: 1,
      prix_total_commande: 10000,
      date_creation_commande: 1579806835797,
      etat_commande: 1,
      is_paid_commande: 1,
      type_commande: 2,
      commandes: [
        {
          id_etablissement: 1,
          prix_total_commande: 5000,
          date_creation_commande: 1579806835797,
          etat_commande: 1,
          is_paid_commande: 1,
          type_commande: 2,
          produits: ["1$2$2500$mon ami", "6$1$2500$test"],
          services: []
        },
        {
          id_etablissement: 2,
          prix_total_commande: 5000,
          date_creation_commande: 1579806835797,
          etat_commande: 1,
          is_paid_commande: 1,
          type_commande: 2,
          produits: [],
          services: ["4$1$5000$mon service"]
        }
      ]
    };
    var cmd1 = {
      id_etablissement: 1,
      prix_total_commande: 5000,
      date_creation_commande: 1579806835797,
      etat_commande: 1,
      is_paid_commande: 1,
      type_commande: 2,
      produits: ["1$2$2500$mon ami", "6$1$2500$test"],
      services: []
    };
    var cmd2 = {
      id_etablissement: 2,
      prix_total_commande: 5000,
      date_creation_commande: 1579806835797,
      etat_commande: 1,
      is_paid_commande: 1,
      type_commande: 2,
      produits: [],
      services: ["4$1$5000$mon service"]
    };
    var cmd3 = {
      code_commande:
        "https://www.squares.seeds.cm/uploads/qr_commande/cmd_1582881967.png",
      date_creation_commande: 1582881966350,
      date_recuperation: 1580625000000,
      date_update_commande: 0,
      etat_commande: 1,
      flash_id: 0,
      id_client: 2,
      id_commande: 505,
      id_commande_parent: 0,
      id_employe: 0,
      id_employe_payer: 0,
      id_transaction: 0,
      is_paid_commande: 1,
      matricule_commande: "cmd_1582881967",
      numero_table: "0",
      prix_total_commande: 80000.0,
      sous_commandes: [
        {
          code_commande:
            "https://www.squares.seeds.cm/uploads/qr_commande/cmd_1582881967.png",
          date_creation_commande: 1582881966341,
          date_recuperation: 0,
          date_update_commande: 0,
          etat_commande: 1,
          flash_id: 0,
          id_client: 2,
          id_commande: 506,
          id_commande_parent: 505,
          id_employe: 0,
          id_employe_payer: 0,
          id_etablissement: 5,
          id_transaction: 0,
          is_paid_commande: false,
          matricule_commande: "cmd_1582881967",
          nom_etablissement: "Zulu",
          numero_table: 0,
          prix_total_commande: 75000.0,
          produits: ["56$2$25000.0$2$"],
          produits_commanders: [
            {
              aDonneSonAvis: false,
              can_be_delivered: 1,
              can_be_reserved: 1,
              couverture_produit_1:
                "https://www.squares.seeds.cm/uploads/image_produit/image_produit1579021308.jpg",
              couverture_produit_2:
                "https://www.squares.seeds.cm/uploads/image_produit/image_produit1579021712.jpg",
              couverture_produit_3:
                "https://www.squares.seeds.cm/uploads/image_produit/image_produit1578938377.jpg",
              couverture_produit_4:
                "https://www.squares.seeds.cm/uploads/image_produit/image_produit1579023056.jpg",
              date_creation_produit: "2020-01-14",
              deep_link: "5/56",
              description_produit:
                "Les plus anciennes distillations attestées par des fouilles archéologiques sont en Mésopotamie au IIe millénaire av. J.-C. mais des traces de distillation apparaissent auparavant en Chine et en Égypte (peintures dans des tombes)2. Les Arabes apportent cette technique en Occident. Les Maures en Espagne l\u0027utilisent pour la fabrication de parfums et de médicaments. Les premières distillations en Europe ont lieu dans les pays possédant des vignobles puis dans les pays qui remplacent les raisins par des grains de céréales3.",
              detail_commande_produit: "",
              details_description: ""
            }
          ]
        }
      ]
    };

    commande1["commandes[]"].push(JSON.stringify(cmd1));
    commande1["commandes[]"].push(JSON.stringify(cmd2));
    // obj.append("total", JSON.stringify(commande1));
    // obj.append("total", JSON.stringify(commande1));
    // obj;

    this.service
      .post(ENDPOINTS.post_customer_order, {
        commande: JSON.stringify(commande2)
      })
      .then((data: any) => {
        console.log(data);
      })
      .catch((data: any) => { });
  }
}
