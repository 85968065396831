import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { LocalStorage } from "../../../shared/helpers/localSortage";
import { MdcDialog } from "@angular-mdc/web";
import { CorePrototype } from "src/app/core/services/core.prototype";

@Component({
  selector: "app-print_page",
  templateUrl: "./print_page.component.html",
  styleUrls: ["./print_page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PrintPageComponent implements OnInit {
  data = [];
  header = [];
  received_data = {
    title: "",
    data: [],
    header: [],
    totals: ['', '', '', '']
  };
  length = 1;
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  print_mode = false;
  print_date = new Date();
  constructor(private service: CorePrototype, public dialog: MdcDialog) { }

  ngOnInit() {
    // Loader.load();
    let that = this;
    window.addEventListener("afterprint", function (event) {
      that.print_mode = false;
      // that.image_print_mode = false;
      // that.context = "Declaration";
    });
    if (LocalStorage.getItem("printing_data") !== null && LocalStorage.getItem("printing_data") !== undefined) {
      this.received_data = JSON.parse(LocalStorage.getItem("printing_data"));
      console.log(this.received_data);
      this.received_data.data.forEach(element => {
        this.length = this.received_data.header.length;
        for (let index = 0; index < this.received_data.header.length; index++) {
          // if (this.received_data.header[index] != 'date_ajout_stock' && this.received_data.header[index] != 'date_peremption_stock') {
          if (typeof (element[this.received_data.header[index]]) === "number") {
            console.log('inside')
            element[this.received_data.header[index]] = element[this.received_data.header[index]].toString(10);
          }
          // }

        }
      });
    }

    this.header = this.received_data.header;
    this.data = this.received_data.data;
    console.log(this.received_data);
  }
  print() {

    this.print_mode = true;

    setTimeout(() => {
      window.print();
    }, 1000);
  }
}
