import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AppComponent } from './app.component';
// import { AuthGuard } from './shared';
import { NotFoundComponent } from './shared/not-found/not-found.component';

import { CoreComponent } from './core/core.component';
import { ConnectUserComponent } from './shared/components/connectUser/connect_user.component';
const routes: Routes = [
  { path: '', redirectTo: 'acceuil', pathMatch: 'full' },
  { path: 'acceuil', component: CoreComponent },
  { path: 'login', component: ConnectUserComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
