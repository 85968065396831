import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { PrintPageComponent } from "../shared/components/print_page/print_page.component";
import { EtablissementsComponent } from "./pages/gestion-ets/etablissements/etablissements.component";


const CoreRoutes: Routes = [
  { path: "", redirectTo: "sales", pathMatch: "full" },
  { path: "ets", component: EtablissementsComponent },
  { path: "print_page", component: PrintPageComponent },
  { path: 'sales', loadChildren: () => import('./pages/sales/sales.module').then(m => m.SalesModule) },
  { path: 'configurations', loadChildren: () => import('./pages/configurations/configurations.module').then(m => m.ConfigurationsModule) },
  { path: 'stocks', loadChildren: () => import('./pages/stocks/stocks.module').then(m => m.StocksModule) },
  { path: 'gestion-ets', loadChildren: () => import('./pages/gestion-ets/gestion-ets.module').then(m => m.GestionEtsModule) },
  // {
  //   path: 'sales',
  //   loadChildren: () => import('./sales/sales.module').then((m) => m.CoursesModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(CoreRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class CoreRoutingModule { }
