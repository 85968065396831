import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PrintPageComponent } from "../shared/components/print_page/print_page.component";
import { SharedModule } from "../shared/components/shared.module";
import { PipeModule } from "../shared/pipes/pipe.module";
import { CoreRoutingModule } from "./core-routing.module";
import { EtablissementsComponent } from "./pages/gestion-ets/etablissements/etablissements.component";
// import { HomeComponent } from "./pages/home/home.component";
import { CorePrototype } from "./services/core.prototype";
import { EtsModalComponent } from './pages/gestion-ets/ets-modal/ets-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipeModule.forRoot(),
    CoreRoutingModule,
    SharedModule
  ],
  declarations: [
    EtablissementsComponent,
    PrintPageComponent,
    EtsModalComponent
  ],
  entryComponents: [
    EtsModalComponent
  ],
  providers: [CorePrototype],
})
export class CoreModule { }
