import { Component, OnInit, Inject } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from "@angular-mdc/web";

import { Router } from "@angular/router";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from '../../helpers/loader';
import { NgForm } from "@angular/forms";
import { DateUtils } from "../../helpers/dateUtils";
import { LocalStorage } from '../../helpers/localSortage';
@Component({
  selector: "app-exposed-product-general-modal",
  templateUrl: "./exposed-product-general-modal.html",
  styleUrls: ["./exposed-product-general-modal.scss"],
})
export class ExposedProductGeneralModal {
  /**
   * The log user keeper
   */
  message: any = {};

  exposed: any = {
    id_produit: 0,
    quantite_stock_expose: 0,
    date_ajout_stock_expose: '',
    id_etablissement: 0,
    id_stock_produit: "0",
    mode: 1,
  };
  produits = [];
  nom_prod = "";
  message_txt = "";
  info_text = "";
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  mode = 1;
  restant = 0;
  // 1 = general, 2 = product
  constructor(
    private route: Router,
    private service: CorePrototype,
    private dialog: MdcDialog,
    public dialogRef: MdcDialogRef<ExposedProductGeneralModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    this.mode = data.mode;
    delete data.mode;
    console.log(data)
    if (this.mode == 2) {

      this.exposed.id_produit = data.id_produit;
      this.exposed.id_etablissement = data.id_etablissement;
      this.exposed.id_stock_produit_expose = data.id_stock_produit_expose;
      this.exposed.id_stock_produit = data.id_stock_produit;
      this.exposed.id_exercice = data.id_exercice;
      this.exposed.date_ajout_stock_expose = data.date_ajout_stock_expose;
      this.exposed.original_stock = data.quantite_stock_expose;
      this.exposed.quantite_stock_expose = data.quantite_stock_expose;
      this.exposed.quantite_stock_expose_restant = data.quantite_stock_expose_restant;
      this.restant = data.quantite_stock_expose_restant;
      this.nom_prod = " (Edition: " + data.nom_produit + ")";
    }
  }

  ngOnInit() {
    this.exposed.date_ajout_stock_expose = DateUtils.dateFormat1s(new Date());
    this.exposed.id_etablissement = this.ets.id_etablissement;
    // if (this.mode == 1) {

      this.service
        .get(ENDPOINTS.get_institute_products, {
          id_etablissement: this.ets.id_etablissement
        })
        .then((data: any) => {
          console.log(data);

          data.body.forEach(element => {
            element.data.produits.forEach(produit => {
              this.produits.push(produit);
            });
          });
          this.produits.sort((a, b) =>
            a.nom_produit.localeCompare(b.nom_produit)
          );
          if (this.mode == 2) {
            var tmp_prod = this.produits.filter((prod) => { return prod.id_produit == this.exposed.id_produit });

            this.info_text = "Info: Ce produit a " + this.restant + " piece(s) exposé et " + tmp_prod[0].quantite_en_stock_reel + " au magasin";

          }
          // this.fournisseurs = data.body;
        });
    // }
  }
  onSelectionChangeProduit(event, id_prod) {
    //info_text
    // console.log(id_prod);
    // console.log(event);
    var tmp_prod = this.produits.filter((prod) => { return prod.id_produit == event.value });
    // this.eve
    this.info_text = "Info: Ce produit a " + tmp_prod[0].quantite_en_stock_reel + " piece(s) en magasin";
  }
  submit(form: NgForm) {
    // console.log(form);
    // console.log(this.exposed);
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }
    var tmp_prod = this.produits.filter((prod) => { return prod.id_produit == this.exposed.id_produit });

    // switch (this.exposed.mode) {
    //   case "0":
    //     delete this.exposed.mode;
    if (this.exposed.quantite_stock_expose <= 0) {
      this.message_txt = "Vous devez inserer une quantite > 0";
      return;
    }
    if (this.mode == 1) {
    if (this.exposed.quantite_stock_expose > tmp_prod[0].quantite_en_stock_reel) {
      this.message_txt = "Vous ne pouvez pas exposer plus qu'il y'en a en magasin";
      return;
    }
    } else {
      if ((this.exposed.quantite_stock_expose - this.exposed.original_stock ) > tmp_prod[0].quantite_en_stock_reel) {
        this.message_txt = "Vous ne pouvez pas exposer plus qu'il y'en a en magasin";
        return;
      }
    }
    delete this.exposed.mode;
    console.log(this.exposed);
    // return;
    if (this.mode == 1) {
      this.exposed.date_ajout_stock_expose = new Date(this.exposed.date_ajout_stock_expose).getTime();
      Loader.load();
      this.service.post(ENDPOINTS.post_add_stock_expose, this.exposed).then((data: any) => {
        console.log(data);
        Loader.stopLoading();
        if (data.body == null || data.body == false) {
          this.message_txt = data.message;
        } else {
          this.dialogRef.close(data.body);

        }
      })
    } else {
      this.exposed.date_ajout_stock_expose = new Date(this.exposed.date_ajout_stock_expose).getTime();
      Loader.load();
      this.service.post(ENDPOINTS.post_update_stock_expose, this.exposed).then((data: any) => {
        console.log(data);
        Loader.stopLoading();
        if (data.body == null || data.body == false) {
          this.message_txt = data.message;
        } else {
          this.dialogRef.close(data.body);

        }
      })
    }

    //     break;
    //   case "1":
    //     break;
    //   default:
    //     break;
    // }


  }
}
