import { Component, Input } from "@angular/core";
// import { mdiAbTesting } from "../../model/mdi";
import * as XLSX from 'xlsx';
import { Loader } from '../../helpers/loader';
import { DialogBox } from '../../helpers/dialogBox';
import { MdcDialog } from '@angular-mdc/web';
import { JSONToCSV } from '../../helpers/JsonToCSV';
import { DateUtils } from '../../helpers/dateUtils';
import { LocalStorage } from '../../helpers/localSortage';
import { Router } from '@angular/router';


@Component({
  selector: "export-menu",
  templateUrl: "./export_menu.component.html",
})
export class ExportMenuComponent {

  @Input("data") data: any;

  @Input("type") type: string = "1";
  @Input("showCFO") showCFO: boolean = false;

  statData = {
    titlePage: "",
    title: "",
    subject: "",
    author: "",
    fileName: "Export",
    other_title: '',
    sheets: [
      {
        sheetName: "",
        sheetData: [],
      }
    ]

  };
  received_data = {
    title: "",
    data: [],
    header: [],
    totals: ['', '', '', '']
  };
  constructor(public dialog: MdcDialog, private router: Router) {

  }
  ngOnInit(): void {
    console.log(this.data);
    console.log(this.type);

  }
  organise_data() {
    this.received_data.title = this.data.title;
    this.received_data.data = this.data.data;
    this.received_data.totals = this.data.totals;
    switch (TYPE_EXPORT[this.type]) {
      case 'Stocks':
        this.received_data.header = ['date_ajout_stock', "nom_categorie_produit", "nom_produit", "prix_unitaire_stock", "quantite_stock", "total_vente", "nom_fournisseur"];
        break;
      case 'Expositions':
        this.received_data.header = ['date_ajout_stock_expose', "nom_categorie_produit", "nom_produit", "prix_unitaire_stock", "quantite_stock_expose", "total_vente"];
        break;
      case 'Commandes':
        this.received_data.header = ['date_ajout_produit_commande', "nom_categorie_produit", "nom_produit", "prix_produit", "total", "total_vente"];
        break;
      case 'Proforma':
        this.received_data.header = ['date_ajout_produit_commande', "nom_categorie_produit", "nom_produit", "prix_produit", "quantite_stock", "total_vente"];
        break;
      case 'Mouvements':
        this.received_data.header = ['date_mouvement', "type_mouvement_txt", "nom_produit", "quantite_initial", "quantite_initial_expose", "quantite_mouvement", "quantite_final", "quantite_final_expose", "nom_fournisseur"];
        break;
      case 'Inventaire':
        this.received_data.header = ['nom_produit', "stock_initial_magasin", "quantite_achat_magasin", "quantite_vente_magasin", "stock_final_magasin", "prix_achat_magasin", "ecart_magasin", "stock_initial_boutique", "quantite_achat_boutique", "quantite_vente_boutique", "stock_final_boutique", "stock_final_boutique", "ecart_boutique"];
        break;
      case 'Retours':
        this.received_data.header = ['date_retour', "nom_produit", "matricule_commande", "prix_produit", "quantite_produit", "prix_total_produit"];
        break;
      case 'Bon de livraison':
        this.received_data.header = ['date_creation_commande', "nom_produit", "prix_produit", "quantite_commande_produit", "prix_total_commande"];
        break;
      case 'Marges':
        this.received_data.header = ['nom_categorie_produit', "nom_produit", "sum_stocks", "sum_retours", "sum_pertes", "sum_num_ventes", "ventes_total", "sum_marges"];
        break;
      case 'Inventaire Journalier':
        this.received_data.header = ['nom_produit', "quantite_final", "quantite_final_expose", "prix_achat_magasin"];
        break;
      case 'Depenses Produits':
        this.received_data.header = ['date_sortie_produit', 'nom_categorie_sortie', "matricule_categorie_sortie", "libelle_sortie_produit", "prix_unitaire_produit", 'quantite_sortie_produit', 'prix_total_produit'];
        break;
      case 'Depenses Charges':
        this.received_data.header = ['nom_categorie_sortie', "matricule_categorie_sortie", "libelle_sortie", "prix_unitaire", 'quantite_sortie', 'prix_total'];
        break;

      default:
        this.received_data.header = ['date_ajout_stock', "nom_categorie_produit", "nom_fournisseur", "nom_produit", "prix_unitaire_stock", "quantite_stock", "total_vente"];
        break;
    }
  }
  exportXLS() {
    // if (this.type == '1') {
    this.organise_data();
    var keys = Object.keys(this.data.data[0]);
    var str_arr = JSON.stringify(this.data.data);
    var XLSData = JSON.parse(str_arr);
    XLSData.forEach(element => {
      for (let index = 0; index < keys.length; index++) {
        // const element = array[index];
        if (!this.received_data.header.includes(keys[index])) {
          delete element[keys[index]];
        }
      }
      // for (var property in element) {
      //   if (element.hasOwnProperty(property)) {
      //     // Do things here
      //   }
      // }
      // element.date_ajout_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_ajout_stock)));
      // element.date_peremption_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_peremption_stock)));
    });
    // }
    console.log(this.data);
    var down = new JSONToCSV(XLSData, "Squares data " + TYPE_EXPORT[this.type], "Squares data " + TYPE_EXPORT[this.type]);
    down.downloader();
    return;

  }
  exportCFO() {
    // if (this.type == '1') {
    this.organise_data();
    var keys = Object.keys(this.data.data[0]);
    var str_arr = JSON.stringify(this.data.data);
    var XLSData = JSON.parse(str_arr);
    var header = ['date_ajout_produit_commande', "nom_produit", "total", "total_vente"];
    var headerCorresponse = ['Date', "Items", "Quantity", "total price"];

    XLSData.forEach(element => {
      for (let index = 0; index < keys.length; index++) {
        // const element = array[index];
        if (!header.includes(keys[index])) {
          delete element[keys[index]];
        }
        else {
          var i = header.findIndex((el) => { return el == keys[index] });
          element[headerCorresponse[i]] = element[keys[index]];
          delete element[keys[index]];
        }
      }
      var actual_date = new Date(parseInt(element.Date));
      // console.log(actual_date);
      element.day = DateUtils.dateFormat2(parseInt(element.Date));
      element.hour = actual_date.getHours();
      element.week_id = actual_date.getDay() + 1;
      element.Date = DateUtils.dateFormatDetails(actual_date);

      // for (var property in element) {
      //   if (element.hasOwnProperty(property)) {
      //     // Do things here
      //   }
      // }
      // element.date_ajout_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_ajout_stock)));
      // element.date_peremption_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_peremption_stock)));
    });
    // }
    console.log(keys)
    console.log(keys[2])
    console.log(XLSData);
    var down = new JSONToCSV(XLSData, "Squares data CFO " + TYPE_EXPORT[this.type], "Squares data CFO " + TYPE_EXPORT[this.type]);
    down.downloader();
    return;

  }
  exportPDF() {
    // this.data.data.forEach(element => {
    //   // element.date_ajout_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_ajout_stock)));
    //   // element.date_peremption_stock = DateUtils.dateFormat1s(new Date(parseInt(element.date_peremption_stock)));
    // });

    this.organise_data();
    console.log(this.received_data);
    LocalStorage.setItem('printing_data', JSON.stringify(this.received_data));
    this.router.navigate(['/print_page']);

    if (this.type == '1') {

    }
  }
  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  display_data() {
    console.log(this.data);
  }
}
export const TYPE_EXPORT = {
  "1": "Stocks",//
  "2": "Expositions",//
  "3": "Commandes",//
  "4": "Proforma",
  "5": "Mouvements",//
  "6": "Inventaire",
  "7": "Retours",//
  "8": "Bon de livraison",//
  "9": "Marges",//
  "10": "Inventaire Journalier",//
  "11": "Depenses Produits",//
  "12": "Depenses Charges",//
}
