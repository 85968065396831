/**
 * @classdesc Translater pipe
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name TranslaterPipe
 */

import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../helpers/language';

/*
 * Make the difference between to dates to return when it has been published.
 * Usage:
 *   text | translator
 * Example:
 *   {{ "Morning" | translator }}
 *   formats to: Bonjour
*/

@Pipe({
  name: 'translator',
  pure: false
})
export class TranslaterPipe implements PipeTransform {

  actualLanguage: any = {};

  /**
   * 
   * @param {LanguageService<Observable> | JSON } lang - Service of message handeler (It can also be a global var of text)
   */
  constructor(private lang: LanguageService) {
    this.lang.getMessage().subscribe((data: any) => {
      this.actualLanguage = data;
      // console.log(data);
    })
  }
  /**
   * Get the term to be translated and can change the langage state
   * 
   * @param {*} term 
   * @returns {string} 
   * @memberof TranslaterPipe
   */
  transform(term: string): string {
    return this.actualLanguage[term];

  }
}
