import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CorePrototype } from 'src/app/core/services/core.prototype';
import { DialogBox } from 'src/app/shared/helpers/dialogBox';
import { ImageUtils } from 'src/app/shared/helpers/imageUtils';
import { Loader } from 'src/app/shared/helpers/loader';
import { LocalStorage } from 'src/app/shared/helpers/localSortage';
import { ENDPOINTS } from 'src/app/shared/model/endpoints';


@Component({
  selector: 'app-create-categorie-modal',
  templateUrl: './create-categorie-modal.component.html',
  styleUrls: ['./create-categorie-modal.component.scss']
})
export class CreateCategorieModalComponent implements OnInit {

  categorie: any = {
    id_categorie: "",
    mode: "1",
    titre: '',
    description: "",
    label_titre: "Nom de la categorie",
    label_description: "Description de la categorie",
    image_categorie: null,
    type_categorie: "1",
    id_departement: "-1"
  };
  fileLogo: any = null;
  deps = [];
  type_user = '0';
  ets = JSON.parse(LocalStorage.getItem('currentEts'));
  constructor(private service: CorePrototype, public dialog: MdcDialog, public sanitizer: DomSanitizer, public dialogRef: MdcDialogRef<CreateCategorieModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    this.categorie.mode = data.mode;
    console.log(data);
    switch (data.mode) {
      case '2':
        this.categorie.titre = data.nom_categorie_produit;
        this.categorie.description = data.description_categorie_produit;
        this.categorie.id_categorie = data.id_categorie_produit;
        this.categorie.image_categorie = data.profil_categorie_produit;
        break;

      case '3':
        this.categorie.titre = data.nom_categorie_service;
        this.categorie.description = data.description_categorie_service;
        this.categorie.id_categorie = data.id_categorie_service;
        this.categorie.image_categorie = data.profil_categorie_service;
        break;

      default:
        break;
    }
  }
  ngOnInit() {
    this.service.get(ENDPOINTS.get_departments, { id_etablissement: this.ets.id_etablissement }).then((data: any) => {
      this.deps = data.body;
    })
  }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < (1 * 1024 * 1024)) {
        this.fileLogo = event.target.files[0];
        this.categorie.image_categorie = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.fileLogo));
      } else {
        DialogBox.showMessage(this.dialog, { title: "Message", body: "Votre image doit peser moins d'un mega" });
      }

    } else {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer un fichier qui est une image image" });
    }

  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez remplir tous les champs" })
      return;
    }
    if (this.categorie.id_departement == '-1') {
      DialogBox.showMessage(this.dialog, { title: "Message", body: "Choisir un departement" })
      return;
    }
    if (this.categorie.mode === '0' || this.categorie.mode === '1') {
      this.categorie.mode = this.type_user;
    }
    console.log(this.categorie)
    switch (this.categorie.mode) {
      case '0':
        var cat: any = { nom_categorie_produit: this.categorie.titre, description_categorie_produit: this.categorie.description, id_departement: this.categorie.id_departement };


        // if (this.fileLogo !== null) {
        //   cat.profil_categorie_produit = this.fileLogo
        // } else {
        //   DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer une image pour creer une categorie" })
        //   return;
        // };
        Loader.load();
        cat.id_etablissement = this.ets.id_etablissement;

        this.service.post(ENDPOINTS.post_categorie_produit, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          this.dialogRef.close(data.body);
        })
        break;
      case '1':
        var cat: any = { nom_categorie_service: this.categorie.titre, description_categorie_service: this.categorie.description, id_departement: this.categorie.id_departement };
        cat.id_etablissement = this.ets.id_etablissement;


        // if (this.fileLogo !== null) {
        //   cat.profil_categorie_service = this.fileLogo
        // } else {
        //   DialogBox.showMessage(this.dialog, { title: "Message", body: "Veuillez inserer une image pour creer une categorie" })
        //   return;
        // };
        Loader.load();

        this.service.post(ENDPOINTS.post_categorie_service, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();
          this.dialogRef.close(data.body);
        })
        break;

      case '2':
        var cat: any = { nom_categorie_produit: this.categorie.titre, description_categorie_produit: this.categorie.description, id_categorie_produit: this.categorie.id_categorie, id_departement: this.categorie.id_departement };
        cat.id_etablissement = this.ets.id_etablissement;

        if (this.fileLogo !== null) {
          cat.profil_categorie_produit = this.fileLogo
        }
        Loader.load();

        this.service.post(ENDPOINTS.post_update_categorie_produit, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();

          this.dialogRef.close(data.body);
        })
        break;
      case '3':
        var cat: any = { nom_categorie_service: this.categorie.titre, description_categorie_service: this.categorie.description, id_categorie_produit: this.categorie.id_service, id_departement: this.categorie.id_departement };
        cat.id_etablissement = this.ets.id_etablissement;

        if (this.fileLogo !== null) {
          cat.profil_categorie_service = this.fileLogo
        }
        Loader.load();

        this.service.post(ENDPOINTS.post_update_categorie_service, cat).then((data: any) => {
          console.log(data);
          Loader.stopLoading();

          this.dialogRef.close(data.body);
        })
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
  radioChange(event) {
    console.log(event);
    this.type_user = event.value;
    // if(event.target.value === )
  }
}
