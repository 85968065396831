import { Component, Inject } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";

import { Router } from "@angular/router";
@Component({
  selector: "select-category-accounting-modal",
  templateUrl: "./select-category-accounting-modal.html",
  styleUrls: ["./select-category-accounting-modal.scss"],
})
export class SelectCategoryAccountingModal {
  /**
   * The log user keeper
   */
  message: any = {};

  constructor(
    private route: Router,
    public dialogRef: MdcDialogRef<SelectCategoryAccountingModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    this.message = data;
  }

  submit(): void {
    this.dialogRef.close(this.message);
  }
}
