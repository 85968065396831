import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { PipeModule } from "../pipes/pipe.module";

import { ConnectUserComponent } from "./connectUser/connect_user.component";
// import { ModalOccupationComponent } from "./modal-occupation/modal-occupation.component";

import { SidebarComponent } from "./sidebar/sidebar.component";
import { DialogConfirmation } from "./dialog-confirmation/dialog-confirmation";
import { DialogMessage } from "./dialog-message/dialog-message";
import { UserCardComponent } from "./user-card/user-card.component";
import {
  MdcButtonModule,
  MdcTextFieldModule,
  MdcLinearProgressModule,
  MdcIconModule,
  MdcDrawerModule,
  MdcTabBarModule,
  MdcCardModule,
  MdcListModule,
  MdcSwitchModule,
  MdcDialogModule,
  MdcFormFieldModule,
  MdcCheckboxModule,
  MdcMenuSurfaceModule,
  MdcRadioModule,
  MdcSelectModule,
  MdcChipsModule,
  MdcElevationModule,
  MdcTypographyModule,
  MDCDataTableModule,
  MdcRippleModule,
  MdcFabModule,
  MdcSnackbarModule,
} from "@angular-mdc/web";

import { CommandeDetailModalComponent } from "./commande-detail-modal/commande-detail-modal.component";
import { ProduitDetailModalComponent } from "./produit-detail-modal/produit-detail-modal.component";
import { UserEditModalComponent } from "./user-edit-modal/user-edit-modal.component";
import { ProduitEditModalComponent } from "./produit-edit-modal/produit-edit-modal.component";
import { ChartsModule } from "ng2-charts";

import { InputTrimModule } from "ng2-trim-directive";
import { UpdatePassComponent } from "./update-pass/update-pass.component";

import { SortieModalComponent } from "./sortie-modal/sortie-modal.component";

import { SelectDepModalComponent } from "./select-dep-modal/select-dep-modal.component";
import { StockModalComponent } from "./stock-modal/stock-modal.component";
import { SortieProduitModalComponent } from "./sortie-produit-modal/sortie-produit-modal.component";
import { SortieServiceModalComponent } from "./sortie-service-modal/sortie-service-modal.component";

import { StockGeneralModalComponent } from "./stock-general-modal/stock-general-modal.component";
import { SelectCategoryMarketplaceModal } from "./select-category-marketplace-modal/select-category-marketplace-modal";
import { SelectCategoryAccountingModal } from "./select-category-accounting-modal/select-category-accounting-modal";
import { OrderDetailsModal } from "./order-details-modal/order-details-modal";
import { IconComponent } from './icon/icon.component';
import { ExposedProductGeneralModal } from './exposed-product-general-modal/exposed-product-general-modal';
import { ExportMenuComponent } from './export_menu/export_menu.component';
import { CritereProduitModal } from './critere-produit-modal/critere-produit-modal';
import { EditCritereProduitModal } from './edit-critere-produit-modal/edit-critere-produit-modal';
import { OptionLivraisonModalComponent } from "src/app/core/pages/stocks/option-livraison-modal/option-livraison-modal.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipeModule.forRoot(),
    MdcLinearProgressModule,
    MdcIconModule,
    InputTrimModule,
    MdcTextFieldModule,
    MdcButtonModule,
    MdcTabBarModule,
    MdcDrawerModule,
    MdcCardModule,
    MdcListModule,
    MdcSwitchModule,
    MdcDialogModule,
    MdcFormFieldModule,
    MdcCheckboxModule,
    MdcMenuSurfaceModule,
    MdcSelectModule,
    MdcRadioModule,
    MdcChipsModule,
    MdcElevationModule,
    MdcTypographyModule,
    ChartsModule,
    MDCDataTableModule,
    MdcRippleModule,
    MDCDataTableModule,
    MdcIconModule,
    MdcTabBarModule,
    MdcButtonModule,
    MdcSwitchModule,
    ChartsModule,
    MdcCardModule,
    MdcTextFieldModule,
    MdcTypographyModule,
    MdcListModule,
    MdcSelectModule,
    MdcCheckboxModule,
    MdcRippleModule,
    MdcChipsModule,
    MdcChipsModule,
    MdcRadioModule,
    MdcSelectModule,
    MdcFabModule,
    MdcSnackbarModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    DialogConfirmation,
    DialogMessage,
    UserCardComponent,
    CommandeDetailModalComponent,
    ProduitDetailModalComponent,
    UserEditModalComponent,
    ProduitEditModalComponent,
    UpdatePassComponent,
    SortieModalComponent,
    SelectDepModalComponent,
    StockModalComponent,
    SortieProduitModalComponent,
    SortieServiceModalComponent,
    StockGeneralModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    ExposedProductGeneralModal,
    ExportMenuComponent,
    CritereProduitModal,
    EditCritereProduitModal,
    OptionLivraisonModalComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    DialogConfirmation,
    DialogMessage,
    UserCardComponent,
    CommandeDetailModalComponent,
    ProduitDetailModalComponent,
    UserEditModalComponent,
    ProduitEditModalComponent,
    UpdatePassComponent,
    StockGeneralModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    ExposedProductGeneralModal,
    ExportMenuComponent,
    CritereProduitModal,
    EditCritereProduitModal,
    OptionLivraisonModalComponent,
    MdcIconModule,
    MdcButtonModule,
    MDCDataTableModule,
    MdcIconModule,
    MdcTabBarModule,
    MdcButtonModule,
    MdcSwitchModule,
    ChartsModule,
    MdcCardModule,
    MdcTextFieldModule,
    MdcTypographyModule,
    MdcListModule,
    MdcSelectModule,
    MdcCheckboxModule,
    MdcRippleModule,
    MdcChipsModule,
    MdcChipsModule,
    MdcRadioModule,
    MdcSelectModule,
    MdcDialogModule,
    MdcFabModule,
    MdcSnackbarModule
  ],
  entryComponents: [
    DialogConfirmation,
    DialogMessage,
    CommandeDetailModalComponent,
    ProduitDetailModalComponent,
    UserEditModalComponent,
    ProduitEditModalComponent,
    UpdatePassComponent,
    SortieModalComponent,
    SortieModalComponent,
    SelectDepModalComponent,
    StockModalComponent,
    SortieProduitModalComponent,
    SortieServiceModalComponent,
    StockGeneralModalComponent,
    SelectCategoryMarketplaceModal,
    SelectCategoryAccountingModal,
    OrderDetailsModal,
    IconComponent,
    ExposedProductGeneralModal,
    ExportMenuComponent,
    CritereProduitModal,
    EditCritereProduitModal,
    OptionLivraisonModalComponent
  ],
})
export class SharedModule { }
