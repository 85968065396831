import { Component, OnInit } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialog, MdcDialogRef } from "@angular-mdc/web";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { ENDPOINTS } from "../../model/endpoints";
import { User } from "../../helpers/user";

@Component({
  selector: "app-update-pass",
  templateUrl: "./update-pass.component.html",
  styleUrls: ["./update-pass.component.scss"]
})
export class UpdatePassComponent implements OnInit {
  user = {
    ancien_pass: "",
    password: "",
    confirm_pass: "",
    email: ""
  };
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<UpdatePassComponent>
  ) {}

  ngOnInit() {
    this.user.email = User.getUser().email;
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    if (this.user.password === this.user.confirm_pass) {
      this.service
        .post(ENDPOINTS.post_update_pass_admin, {
          password: this.user.password,
          id_utilisateur: User.getUserId(),
          ancien_pass: this.user.ancien_pass,
          email: this.user.email
        })
        .then((data: any) => {
          if (data.status === "failed") {
            DialogBox.showMessage(this.dialog, {
              title: "Modification impossible",
              body: "Assurez vous d'avoir bien inserer l'ancien mot de passe"
            });
          } else {
            this.dialogRef.close(data.body);
          }
        });
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Nouveau mot de passe et confirmation non identique"
      });
      return;
    }
  }
}
