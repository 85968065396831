import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Component, OnInit, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { CorePrototype } from "src/app/core/services/core.prototype";
import { DialogBox } from "src/app/shared/helpers/dialogBox";
import { ImageUtils } from "src/app/shared/helpers/imageUtils";
import { Loader } from "src/app/shared/helpers/loader";
import { LocalStorage } from "src/app/shared/helpers/localSortage";
import { ENDPOINTS } from "src/app/shared/model/endpoints";


@Component({
  selector: "app-department-modal",
  templateUrl: "./department-modal.component.html",
  styleUrls: ["./department-modal.component.scss"]
})
export class DepartmentModalComponent implements OnInit {
  ets = {
    nom_departement: "",
    logo_departement: null,
    mode: "0",
    description_departement: "",
    nombre_tables: "",
    id_departement: "",
    id_etablissement: ""
  };
  fileLogo = null;
  ets_actuel = JSON.parse(LocalStorage.getItem("currentEts"));

  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<DepartmentModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data.mode === "0") {
      this.ets.mode = data.mode;
    } else {
      this.ets = data;
    }
  }

  ngOnInit() { }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1 * 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.ets.logo_departement = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega"
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image"
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    var tmp_ets = Object.assign(this.ets, {});
    tmp_ets.id_etablissement = this.ets_actuel.id_etablissement;

    console.log(tmp_ets);
    switch (tmp_ets.mode) {
      case "0":
        if (this.fileLogo !== null) {
          tmp_ets.logo_departement = this.fileLogo;
        } else {
          // delete cat.couverture_profil;
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour créer un departement"
          });
          return;
        }
        delete tmp_ets.mode;
        delete tmp_ets.id_departement;
        // delete tmp_prod.id_type_produit;
        Loader.load();

        this.service
          .post(ENDPOINTS.post_department, tmp_ets)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        if (this.fileLogo !== null) {
          tmp_ets.logo_departement = this.fileLogo;
        }
        delete tmp_ets.mode;
        // delete tmp_ets.categories;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        var final = {
          id_etablissement: tmp_ets.id_etablissement,
          id_departement: tmp_ets.id_departement,
          logo_departement: tmp_ets.logo_departement,
          description_departement: tmp_ets.description_departement,
          nom_departement: tmp_ets.nom_departement
        };
        Loader.load();

        this.service
          .post(ENDPOINTS.post_update_department, final)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
}
