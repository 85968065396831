import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
@Component({
  selector: 'user-card-component',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  /**
   * The log user keeper
   */
  user: any = {};

  constructor( private route: Router) { }

  ngOnInit() {
    // this.user = User.getUser();
   
  }
}
