import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import {
  MdcDialogRef,
  MDC_DIALOG_DATA,
  MdcDialog,
  MdcSelectChange,
} from "@angular-mdc/web";

import { Router } from "@angular/router";
import { STATE } from "../../model/codes";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { DialogBox } from "../../helpers/dialogBox";
import { NgForm } from "@angular/forms";
import { DateUtils } from '../../helpers/dateUtils';
@Component({
  selector: "app-order-details-modal",
  templateUrl: "./order-details-modal.html",
  styleUrls: ["./order-details-modal.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsModal {
  /**
   * The log user keeper
   */
  message: any = {};
  commande: any = {};
  client: any = {
    id_client: "0",
    nom_client: "",
    prenom_client: "",
    email_client: "",
    numero_client: "",
  };
  option_livraison: any = {
    id_option_livraison: "0",
    nom_option_livraison: "",
    prix_option_livraison: 0,
  };
  owner: any = {
    nom: "",
    email: "",
    localisation: "",
  };
  sum_prix_prods = 0;
  showModif = true;
  etat = "";
  serveuse = { nom_employe: "", id_employe: "" };
  constructor(
    private service: CorePrototype,
    private dialog: MdcDialog,
    private route: Router,
    public dialogRef: MdcDialogRef<OrderDetailsModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    data.produits_commanders.forEach((element) => {
      element.etat_commande_produit = STATE[element.etat_commande_produit];
      this.sum_prix_prods =
        this.sum_prix_prods + parseFloat(element.prix_produit);
    });
    data.services_commanders.forEach((element) => {
      element.etat_commande_service = STATE[element.etat_commande_service];
    });
    data.date_creation_commande = DateUtils.dateFormat1s(new Date(data.date_creation_commande));
    this.commande = data;
    // this.etat = STATE[data.etat_commande];
    if (this.commande.id_client != "0") {
      this.service
        .get(ENDPOINTS.get_customer, { id_client: this.commande.id_client })
        .then((data: any) => {
          this.client = data.body;
        });
    }
    if (this.commande.id_option_livraison != "0") {
      this.service
        .get(ENDPOINTS.get_option_livraison, {
          id_option_livraison: this.commande.id_option_livraison,
        })
        .then((data: any) => {
          this.option_livraison = data.body;
        });
    }
    if (this.commande.id_employe != "0") {
      this.service
        .get(ENDPOINTS.get_employee, { id_employe: this.commande.id_employe })
        .then((data: any) => {
          this.serveuse = data.body;
        });
    }

    console.log(data);
  }

  ngOnInit() {
    // this.service.get(ENDPOINTS.get_user, { id_user: this.commande.id_user }).then((user: any) => {
    //   this.owner = user.body;
    // })
    if (
      this.commande.etat_commande == "3" ||
      this.commande.etat_commande == "5"
    ) {
      if (this.commande.id_type_produit) {
        this.showModif = true;
      } else {
        this.showModif = false;
      }
    }
  }
  updatePaid(event: MdcSelectChange) {
    console.log(event);
    if (event.source.ngControl.control.touched == false) {
    } else {
    Loader.load();
    this.service
      .post(ENDPOINTS.post_update_order, {
        firebase_user_token: "",
        etat_commande: this.commande.etat_commande_int,
        id_commande: this.commande.id_commande,
        is_paid_commande: event.value,
      })
      .then((user: any) => {
        Loader.stopLoading();
        console.log(user);
        // this.dialogRef.close(user.body);
      });
    }
  }
  updateDelivery(event: MdcSelectChange) {
    console.log(event);
    if (event.source.ngControl.control.touched == false) {
    } else {
    Loader.load();
    this.service
      .post(ENDPOINTS.post_update_order, {
        firebase_user_token: "",
        etat_commande: event.value,
        id_commande: this.commande.id_commande,
        is_paid_commande: this.commande.is_paid_commande,
      })
      .then((user: any) => {
        Loader.stopLoading();
        console.log(user);
        // this.dialogRef.close(user.body);
      });
    }
  }
  submit(params: NgForm) {}
  updateCommande(value) {
    this.commande.etat_commande = value;
    // this.commande.firebase_user_token = '';
    console.log(this.commande.type_categorie);
    console.log(value);
    if (this.commande.type_categorie === "1" && value === "2") {
      this.commande.etat_commande = "6";
    }
    if (value === "5" && this.commande.etat_commande === "3") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours",
      });
      return;
    }
    if (value === "5" && this.commande.etat_commande === "6") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body:
          "Desolé, mais vous dne pouvez pas annuler une commande déja en cours",
      });
      return;
    }
    Loader.load();
    // this.service.post(ENDPOINTS.post_update_order, { firebase_user_token: '', etat_commande: this.commande.etat_commande, id_commande: this.commande.id_commande,commentaire_commande:this.commande.commentaire_commande }).then((user: any) => {
    //   Loader.stopLoading();
    //   console.log(user);
    //   this.dialogRef.close(user.body);
    // })
  }
}
