import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  MdcButtonModule,
  MdcTextFieldModule,
  MdcCardModule,
  MdcLinearProgressModule
} from "@angular-mdc/web";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppInterceptor } from "./shared/interceptors/app.interceptor";
import { ErrorsHandler } from "./shared/helpers/errorHandeler";
import { RequestService } from "./shared/services/request.service";
// import { MessagingService } from "./shared/services/messaging.service";
// import { AngularFireDatabaseModule } from "@angular/fire/database";
// import { AngularFireAuthModule } from "@angular/fire/auth";
// import { AngularFireMessagingModule } from "@angular/fire/messaging";
// import { environment } from "../environments/environment";
// import { AngularFireModule } from "@angular/fire";
import { CoreComponent } from "./core/core.component";
// import { ServiceWorkerModule } from "@angular/service-worker";
import { SharedModule } from "./shared/components/shared.module";
import { CoreRoutingModule } from "./core/core-routing.module";
import { ConnectUserComponent } from "./shared/components/connectUser/connect_user.component";
import { NotFoundComponent } from "./shared/not-found/not-found.component";
import { FormsModule } from "@angular/forms";
import { CoreModule } from "./core/core.module";
import { DateTableService } from "./shared/services/date-table.service";
@NgModule({
  declarations: [
    AppComponent,
    CoreComponent,
    ConnectUserComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    CoreRoutingModule,
    MdcButtonModule,
    MdcTextFieldModule,
    MdcLinearProgressModule,
    MdcCardModule
  ],
  providers: [

    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    RequestService,
    DateTableService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// ServiceWorkerModule.register("ngsw-worker.js", {
//   enabled: environment.production
// });
