import { Component, OnInit, Inject } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from "@angular-mdc/web";

import { Router } from "@angular/router";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from "../../helpers/loader";
@Component({
  selector: "app-select-category-marketplace-modal",
  templateUrl: "./select-category-marketplace-modal.html",
  styleUrls: ["./select-category-marketplace-modal.scss"],
})
export class SelectCategoryMarketplaceModal {
  /**
   * The log user keeper
   */
  message: any = {};
  squares = [];
  selected_square = { id_caree: "0", sous_caree: [] };
  sub_squares = [];
  selected_sub_square = { id_caree: "0", categories_caree: [] };
  categories_caree = [];
  selected_categories_caree = { id_categorie_caree: "0" };
  constructor(
    private route: Router,
    private service: CorePrototype,
    private dialog: MdcDialog,
    public dialogRef: MdcDialogRef<SelectCategoryMarketplaceModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    this.message = data;
  }

  ngOnInit() {
    Loader.loadBack();
    this.service.get(ENDPOINTS.get_squares_with_sub).then((data: any) => {
      Loader.stoploadingBack();
      console.log(data);
      this.squares = data.body;
    });
  }
  submit(): void {
    if (this.selected_square.id_caree == "0") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Choissisez un caree",
      });
      return;
    }
    if (this.selected_sub_square.id_caree == "0") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Choissisez un sous caree",
      });
      return;
    }
    if (this.selected_categories_caree.id_categorie_caree == "0") {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Choissisez une categorie de caree",
      });
      return;
    }
    var trio = {
      square: this.selected_square,
      sub_square: this.selected_sub_square,
      categorie_square: this.selected_categories_caree,
    };
    this.dialogRef.close(trio);
  }
  selectSquare(square) {
    this.selected_square = square;
    this.selected_sub_square = { id_caree: "0", categories_caree: [] };
    this.selected_categories_caree = { id_categorie_caree: "0" };
    this.categories_caree = [];
  }
  selectSubSquare(square) {
    this.selected_sub_square = square;
    console.log(square);

    this.selected_categories_caree = { id_categorie_caree: "0" };
  }
  selectCategorieSquare(categorie) {
    this.selected_categories_caree = categorie;
  }
}
