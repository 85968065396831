/**
 * @classdesc Helper to show messages.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name DialogBox
 */

import { MdcDialog } from '@angular-mdc/web';
import { DialogMessage } from '../components/dialog-message/dialog-message';
import { DialogConfirmation } from '../components/dialog-confirmation/dialog-confirmation';

export class MessageModel {
    title: String;
    body: String;
}

export class DialogBox {
    static publicDialog: MdcDialog;

    constructor(public dialog: MdcDialog) {
        DialogBox.publicDialog = this.dialog;
    }
    /**
     * Display a message in a cool modal
     * @param {MdcDialog} dial The dialog class of type MdcDialog
     * @param {MessageModel} message Refer to the message model above
     */
    static showMessage(dial: MdcDialog, message: MessageModel) {
        console.log(dial);
        // console.log(DialogMessage);
        const dialogRef = dial.open(DialogMessage, {
            escapeToClose: false,
            clickOutsideToClose: false,
            buttonsStacked: false,
            id: 'my-dialog',
            data: message
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
        });
    }
    /**
     * Display a message in a cool modal
     * @param {MdcDialog} dial The dialog class of type MdcDialog
     * @param {MessageModel} message Refer to the message model above
     * @returns {Promise<any>} contains result of the choise done by user
     */
    static confirmationMessage(dial: MdcDialog, message: MessageModel): Promise<any> {
        // console.log(this.dialog);
        // console.log(Swaler.publicDialog);
        const dialogRef = dial.open(DialogConfirmation, {
            escapeToClose: false,
            clickOutsideToClose: false,
            buttonsStacked: false,
            id: 'my-dialog-confirmation',
            data: message
        });
        return new Promise(function (resolve, reject) {
            dialogRef.afterClosed().subscribe(result => {
                resolve(result);
            })
        });
    }


}