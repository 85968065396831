import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { MethodUtils } from "../../../shared/helpers/methodUtils";
import { Router } from "@angular/router";
import { _ } from "underscore";
import { LocalStorage } from "../../helpers/localSortage";
import { CorePrototype } from "src/app/core/services/core.prototype";
import { User } from "../../../shared/helpers/user";
import { MdcDialog } from "@angular-mdc/web";
import { StockGeneralModalComponent } from "../stock-general-modal/stock-general-modal.component";
// import { ViewEncapsulation } from "@angular/core";

declare var jQuery: any;
declare var M: any;

@Component({
  selector: "sidebar-component",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  user: any = {};
  open = false;
  destinations = [
    {
      title: "Ventes",
      menus: [
        {
          label: "Tableau de bord",
          icon: "mdiMonitorDashboard",
          route: "sales/home",
          index: 2,
        },
        { label: "Factures", icon: "mdiCreditCardOutline", route: "sales/commandes", index: 4 },
        {
          label: "Factures proforma",
          icon: "mdiNewspaper",
          route: "sales/proforma",
          index: 28,
        },
        {
          label: "Declarer les ventes",
          icon: "mdiCartPlus",
          route: "sales/facturation",
          index: 78,
        },
        {
          label: "Ventes",
          icon: "mdiSack",
          route: "sales/ventes",
          index: 77,
        },
        {
          label: "Recettes",
          icon: "mdiCash",
          route: "sales/recettes",
          index: 19,
        }

      ]
    },
    {
      title: "Gestion des stocks",
      menus: [
        {
          label: "Gerer les fournisseur",
          icon: "mdiBriefcasePlus",
          route: "stocks/fournisseurs",
          index: 34,
        },
        { label: "Articles et produits", icon: "mdiBriefcaseVariant", route: "stocks/articles", index: 6 },
        {
          label: "Approvisionnement / Magasin",
          icon: "mdiBriefcaseDownloadOutline",
          route: "stocks/stocks",
          index: 35,
        },
        {
          label: "Exposition / Boutique",
          icon: "mdiStore",
          route: "stocks/expositions",
          index: 41,
        },
        {
          label: "Inventaire",
          icon: "mdiClipboardList",
          route: "stocks/inventaire",
          index: 42,
        },
        {
          label: "Inventaire Jour.",
          icon: "mdiClipboardCheckOutline",
          route: "stocks/inventaire_jour",
          index: 43,
        },
        {
          label: "Exercice",
          icon: "mdiCalendarSync",
          route: "gestion-ets/exercice",
          index: 44,
        },
        {
          label: "Mouvement de stock",
          icon: "mdiCogSyncOutline",
          route: "stocks/mouvements",
          index: 36,
        },
        {
          label: "Retours en stock",
          icon: "mdiArrowDownBoldBoxOutline",
          route: "stocks/retours",
          index: 37,
        },
        {
          label: "Bon de livraison",
          icon: "mdiTicket",
          route: "stocks/bon_livraisons",
          index: 38,
        },
      ]
    },
    {
      title: "Gestion de l'etablissement",
      menus: [
        { label: "Départements", icon: "mdiGoogleClassroom", route: "gestion-ets/dep", index: 3 },
        { label: "Catégories", icon: "mdiShape", route: "gestion-ets/categories", index: 7 },
        { label: "Clients", icon: "mdiBadgeAccount", route: "gestion-ets/clients", index: 80 },
        { label: "Employés", icon: "mdiAccountHardHat", route: "gestion-ets/employes", index: 5 },
        {
          label: "Bulk upload",
          icon: "mdiFolderPlus",
          route: "gestion-ets/bulk_upload",
          index: 8,
        },
        {
          label: "Options de livraisons",
          icon: "mdiTruck",
          route: "gestion-ets/livraison",
          index: 102,
        },
      ]
    },

    {
      title: "Dépenses",
      menus: [

        {
          label: "Config sorties",
          icon: "mdiClipboardFlow",
          route: "gestion-ets/sorties",
          index: 9,
        },
        {
          label: "Declarer des pertes",
          icon: "mdiClipboardFlowOutline",
          route: "gestion-ets/sorties_all",
          index: 10,
        },
      ]
    },
    {
      title: "Paramétres",
      menus: [
        {
          label: "Mon Etablissement",
          icon: "mdiDomain",
          route: "configurations/settings",
          index: 11,
        },
        {
          label: "Paramétrage",
          icon: "mdiCogBox",
          route: "configurations/params",
          tag: "Prestige",
          index: 33,
        },
      ]
    },

  ];
  submissionMenu = [
    {
      label: "Gestion des stocks",
      icon: "dns",
      sub: [
        { label: "Approvisioner", icon: "shopping_cart", route: "add_stock" },
        {
          label: "Declarer des pertes",
          icon: "trending_down",
          route: "declare_loss",
        },
        {
          label: "Gerer des fournisseur",
          icon: "local_shipping",
          route: "add_supplier",
        },
      ],
    },
    {
      label: "Ventes & Facturation",
      icon: "shopping_basket",
      sub: [
        {
          label: "Declarer les ventes",
          icon: "local_atm",
          route: "facturation",
          index: 8,
        },
        {
          label: "Afficher les rapports ",
          icon: "assessment",
          route: "report",
        },
      ],
    },
  ];
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private router: Router,
    private service: CorePrototype,
    private dialog: MdcDialog
  ) { }
  ngOnInit() {
    this.user = User.getUser();
    // console.log(this.router);
    // console.log(this.router.url);
    // console.log(this.ets);


  }
  ngAfterViewInit() {
    var that = this;

    // this.destinations.forEach((element: any) => {
    //   // console.log(element.route);
    //   if ("/" + element.route === this.router.url) {
    //     jQuery("." + element.route).addClass("mdc-list-item--activated");
    //     // console.log('#mdc-list-item-' + element.index);
    //   }
    // });
  }
  openMenu() {
    if (this.open === false) {
      this.open = true;
      this.showMenu();
    } else {
      this.open = false;
      // jQuery(".menutext").hide();
      this.hideMenu();
    }
  }
  showMenu() {
    jQuery("#menu").css({
      transform: "translateX(1%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "1",
      display: "block",
    });
  }
  hideMenu() {
    jQuery("#menu").css({
      transform: "translateX(-100%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "0",
      display: "none",
    });
  }
  goToMenu(fragment) {
    document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
  }
  routeMenu(item) {
    console.log(item);
    var dialogRef = null;
    switch (item.route) {
      case "add_stock":
        dialogRef = this.dialog.open(StockGeneralModalComponent, {
          data: { mode: "0" },
        });

        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          // if (resultModal !== "close" || resultModal !== null) {
          //   if (resultModal.id_etablissement) {
          //     this.entreprises.push(resultModal);
          //     return;
          //   }
          // }
        });
        break;
      case "declare_loss":
        this.router.navigateByUrl("sorties_all");
        break;
      case "add_supplier":
        this.router.navigateByUrl("fournisseurs");

        // dialogRef = this.dialog.open(FournisseurModalComponent, {
        //   data: { mode: "0" }
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        //   // if (resultModal !== "close" || resultModal !== null) {
        //   //   if (resultModal.id_etablissement) {
        //   //     this.entreprises.push(resultModal);
        //   //     return;
        //   //   }
        //   // }
        // });
        break;
      case "facturation":
        this.router.navigateByUrl("facturation");

        break;
      case "report":
        this.router.navigateByUrl("facturation");

        break;

      default:
        break;
    }
  }
}
