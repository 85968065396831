
/**
 * @classdesc Helper to dates.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2018
 * @name DateUtils
 */

export class DateUtils {
  constructor() {
  }

  /**
   * Get the difference between two date
   * @param debut Starting date
   * @param fin ending date
   * @returns number
   */
  datediff(debut, fin) {
    fin = new Date(fin);
    debut = new Date(debut);
    // console.log(lieu);
    const timeDiff = fin.getTime() - debut.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
  static datediffs(debut, fin) {
    fin = new Date(fin);
    debut = new Date(debut);
    // console.log(lieu);
    const timeDiff = fin.getTime() - debut.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  /**
   * Returns a date format yyyy-mm-dd
   * @param date
   * @returns string
   */
  dateFormat1(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  static dateFormat1(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  /**
   * Returns a date format yyyy-mm-dd
   * @param date
   * @returns string
   */
  static dateFormat1s(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  /**
   * Returns a date format dd/mm/yyy
   * @param date
   * @returns string
   */
  dateFormat2(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    // console.log(day, month, year);
    return [day, month, year].join('/');
  }
  static dateFormat2(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    // console.log(day, month, year);
    return [day, month, year].join('/');
  }
  static dateFormatDetails(date) {
    date = new Date(date);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let hours_txt = '';
    let minutes_txt = '';

    if (hours.toString().length < 2) {
      hours_txt = '0' + hours.toString();
    } else {
      hours_txt = hours.toString();
    }

    if (minutes.toString().length < 2) {
      minutes_txt = '0' + minutes.toString();
    } else {
      minutes_txt = minutes.toString();
    }

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    // console.log(day, month, year);
    return [day, month, year].join('/') + " " + hours_txt + ":" + minutes_txt;
  }
  /**
   * Returns a date format dd-mm-yyyy
   * @param date
   * @returns string
   */
  dateFormat3(date) {
    let newDate = new Date(date);
    console.log(date);
    console.log(newDate.getDate());
    if (newDate.getDate() === NaN) {
      return date;
    } else {
      let month = '' + (newDate.getMonth() + 1);
      let day = '' + newDate.getDate();
      let year = newDate.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [day, month, year].join('-');
    }

  }
  /**
   * Join dates
   * @param date
   * @returns string
   */
  dateFormat4(date) {
    var subdates = date.split('-');
    // console.log([subdates[2],subdates[1],subdates[0]].join('-'));
    return [subdates[2], subdates[1], subdates[0]].join('-');
  }
  getDay(curr: any) {
    let actualDate, actualWeek = [];
    if (typeof (curr) !== 'object') {
      curr = new Date(curr);
      actualDate = this.dateFormat1(new Date(curr.setDate(curr.getDate())));
    }
    curr.setDate((curr.getDate() - curr.getDay()));

    for (var i = 0; i < 7; i++) {
      actualWeek[i] = { 'dayDate': this.dateFormat1(new Date(curr)), events: [] };
      curr.setDate(curr.getDate() + 1);
    }
  }
  /**
   * Returns a string of a particular length
   * @param element Sting to be splited
   * @param length length of wanted string
   * @returns string
   */
  stringSplitter(element, length) {
    if (element !== undefined) {
      element = element.substr(0, length);
    }
    if (element.length === length) {
      element = element + String.fromCharCode(8230);
    }
    return element;

  }
  /**
   * Returns a string of a particular length
   * @param element Sting to be splited
   * @param length length of wanted string
   * @returns string
   */
  stringSplitter2(element, length) {
    if (element !== undefined) {
      element = element.substr(0, length);
    }
    // if (element.length === length) {
    //     element = element + '...'
    // }
    return element;

  }
  static actualDay(curr, option) {
    // var tmp=[];
    // var curr2 = new Date(curr);
    var actualWeek = [];
    if (typeof (curr) !== 'object') {
      curr = new Date(curr);
      // this.actualDate = this.convertDate(new Date(curr.setDate(curr.getDate())));
    }
    curr.setDate((curr.getDate() - curr.getDay()));

    for (var i = 0; i < 7; i++) {
      var local_date = new Date(curr);
      let month = '' + (local_date.getMonth() + 1);
      let day = '' + local_date.getDate();
      let year = local_date.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      var finalDate = [year, month, day].join('-');

      actualWeek[i] = { 'dayDate': finalDate };
      curr.setDate(curr.getDate() + 1);
      var localDate = new Date(actualWeek[i].dayDate)
      var stamp = localDate.getTime();
      actualWeek[i].timestamp = stamp;
    }
    return actualWeek;
  }
  convertDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}
