import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { DomSanitizer } from "@angular/platform-browser";
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { ProduitEditModalComponent } from "../produit-edit-modal/produit-edit-modal.component";
import { CreateCategorieModalComponent } from "src/app/core/pages/gestion-ets/create-categorie-modal/create-categorie-modal.component";
import { DepartmentModalComponent } from "src/app/core/pages/gestion-ets/department-modal/department-modal.component";

@Component({
  selector: "app-select-dep-modal",
  templateUrl: "./select-dep-modal.component.html",
  styleUrls: ["./select-dep-modal.component.scss"]
})
export class SelectDepModalComponent implements OnInit {
  department: any = {};
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<SelectDepModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    this.department = data;
  }

  ngOnInit() { }
  closeDialog(type) {
    var dialogRefTmp: MdcDialogRef<any>;
    switch (type) {
      case "1":
        dialogRefTmp = this.dialog.open(DepartmentModalComponent, {
          data: { mode: "0" }
        });
        dialogRefTmp.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          if (resultModal !== "close" || resultModal !== null) {
            this.dialogRef.close(resultModal);
            return;
          } else {
            this.dialogRef.close(null);
          }
        });
        break;
      case "2":
        dialogRefTmp = this.dialog.open(ProduitEditModalComponent, {
          data: { mode: "0" }
        });
        dialogRefTmp.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          if (resultModal !== "close" || resultModal !== null) {
            this.dialogRef.close(resultModal);
            return;
          } else {
            this.dialogRef.close(null);
          }
        });
        break;
      case "3":
        dialogRefTmp = this.dialog.open(ProduitEditModalComponent, {
          data: { mode: "0" }
        });
        dialogRefTmp.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          if (resultModal !== "close" || resultModal !== null) {
            this.dialogRef.close(resultModal);
            return;
          } else {
            this.dialogRef.close(null);
          }
        });
        break;
      case "4":
        dialogRefTmp = this.dialog.open(CreateCategorieModalComponent, {
          data: { mode: "0" }
        });
        dialogRefTmp.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          if (resultModal !== "close" || resultModal !== null) {
            this.dialogRef.close(null);
            return;
          } else {
            this.dialogRef.close(null);
          }
        });
        break;

      default:
        break;
    }
    // this.dialogRef.close();
  }
}
