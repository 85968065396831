import { Component, Inject, OnInit } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA, MdcDialog } from '@angular-mdc/web';
declare var jQuery: any;

import { Router } from "@angular/router";
import { CorePrototype } from '../../../core/services/core.prototype';
import { ENDPOINTS } from '../../model/endpoints';
import { UNITE_CRITERES } from '../../model/codes';
import { Loader } from '../../helpers/loader';
import { DialogBox } from '../../helpers/dialogBox';
@Component({
  selector: "app-edit-critere-produit-modal",
  templateUrl: "./edit-critere-produit-modal.html",
  styleUrls: ["./edit-critere-produit-modal.scss"],
})
export class EditCritereProduitModal implements OnInit {
  /**
   * The log user keeper
   */
  message: any = {};
  id_categorie_caree = '2';
  id_produit = '2';

  valeur_critere_produit = '';
  prod_criteres = [];
  criteres_categ = [];
  critere_produit: any = {
    nom_critere_produit: "",
    valeur_critere_produit: "",
    unite_critere_produit: "",
    example_critere_produit: "",
    id_critere_produit: "",
    id_avoir_critere_produit: "",
    type: "1"
  }
  val_unites = UNITE_CRITERES;
  constructor(
    private route: Router,
    public dialog: MdcDialog,
    private service: CorePrototype,
    public dialogRef: MdcDialogRef<EditCritereProduitModal>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data.valeur_critere_produit) {
      this.critere_produit = data;

    } else {
      data.valeur_critere_produit = "";
      this.critere_produit = data;
      this.critere_produit.type = "1"

    }
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
  submit(): void {
    console.log(this.critere_produit);
    if (this.critere_produit.valeur_critere_produit.length < 1) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    this.dialogRef.close(this.critere_produit);
    // this.critere_produit = {
    //   nom_critere_produit: "",
    //   valeur_critere_produit: "",
    //   unite_critere_produit: "",
    //   example_critere_produit: "",
    //   id_critere_produit: ""
    // }

  }
  logTab(event) {
    console.log(event);
  }
  showTab(i) {
    jQuery(".tab-body").hide();
    jQuery("#" + i).show();
    // this.showTabArticle("1");
    // jQuery("#tab_prod_ult").click();
  }
}
