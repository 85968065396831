import { Component, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';

import { Router } from '@angular/router';
@Component({
  selector: 'dialog-message',
  templateUrl: './dialog-message.html',
  styleUrls: ['./dialog-message.scss']
})
export class DialogMessage {
  /**
   * The log user keeper
   */
  message: any = {};

  constructor(private route: Router, public dialogRef: MdcDialogRef<DialogMessage>,
    @Inject(MDC_DIALOG_DATA) public data: any) {
    this.message = data;
  }


  submit(): void {

    this.dialogRef.close(this.message);
  }
}
