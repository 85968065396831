/**
 * @classdesc Global user gestion.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2019
 * @name User
 */

import { LocalStorage } from './localSortage';
export class User {
    constructor() {

    }

    /**
     * determine wheather or not a user is authenticate
     */
    static isConnect(): Boolean {
        return LocalStorage.getItem("Squares_user") != undefined;
    }

    /**
     * Add user data to the local DB
     * @param {*} user user object to be saved
     */
    connect(user: any) {
        LocalStorage.setItem("Squares_user", JSON.stringify(user));
    }
    /**
     * Remove user data to the local DB
     */
    static disconect() {
        LocalStorage.delete("Squares_user");
    }

    /**
     * Get the current log user
     */
    static getUser(): any {
        return JSON.parse(LocalStorage.getItem("Squares_user"));
    }
    static getUserId(): any {
        var user = LocalStorage.getItem("Squares_user");
        var userJson = JSON.parse(user);

        return userJson.id_utilisateur;
    }
    static getUserToken(): any {
        var user = LocalStorage.getItem("Squares_user");
        var userJson = JSON.parse(user);
        if (userJson === null || userJson === undefined) {
            return 'ok';
        }
        return userJson.token;
    }

}