/**
 * @classdesc Gets the actual language used by the app, stores it and renders it usable for the whole app particularly the pipe.
 * @author Melo
 * @version 1.0.0
 * @copyright Seed Developpers 2018
 * @name LanguageService
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
@Injectable()
export class LanguageService {
    public account = {};
    public functions = {};
    public routes = {};

    private actualLanguage = new Subject<any>();
    private actualCode = new Subject<any>();
    public langCode;
    public languageObject;
    constructor() {
        this.init();
        // this.generateFunctions();
        this.routes["account"] = this.account;
        this.routes["functions"] = this.functions;
    }

    sendMessage(message) {
        this.actualLanguage.next(message);
        // console.log(message);
    }

    clearMessage() {
        this.actualLanguage.next();
    }
    sendLang(message){
        this.actualCode.next(message);
    }
    getMessage(): Observable<any> {
        // console.log(this.actualLanguage);
        return this.actualLanguage.asObservable();
    }
    getTranslate(term) : Observable<any> {
        return this.actualLanguage[term].asObservable();
    }
    getLang():Observable<any>{
        return this.actualCode.asObservable();
    }
    /** 
     * Init services object 
    */
    init() {
        this.account = {
            "event": {
                "en": "/assets/lang/en.json",
                "fr": "/assets/lang/fr.json",
            },
        }
    }

    /** 
     * services generator / services factory
     * @inner { Object } data - the post data
       * @inner { String } urlParams -  the url add parameters
    */
    // generateFunctions() {
    //     var that = this;
    //     for (let data in this.account) {
    //         let modules = this.account[data];
    //         for (let links in modules) {
    //             let link = modules[links];
    //             this.functions[links] = function (urlParams) {
    //                 return new Promise(function (resolve) {
    //                     link = (urlParams == undefined) ? link : link + "/" + urlParams;
    //                     that.request.get(link).subscribe(function (result) {
    //                         resolve(result);
    //                         // console.log(this.functions);
    //                         // if(urlParams==="en"){
    //                         //     this.langCode=1;
    //                         //     this.sendLang({'lang':'1'});
    //                         // }
    //                         // if(urlParams==="fr"){
    //                         //     this.langCode=2;
    //                         //     this.sendLang({'lang':'2'});
    //                         // }
    //                         that.sendMessage(result);
    //                         link = link.replace('/' + urlParams, "");
    //                     })
    //                     link = link.replace('/' + urlParams, "");
    //                 });
    //             }
    //         }
    //     }
    // }

}
