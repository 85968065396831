import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { ENDPOINTS } from "../../model/endpoints";
import { Loader } from "../../helpers/loader";
import { LocalStorage } from "../../helpers/localSortage";

@Component({
  selector: "app-stock-modal",
  templateUrl: "./stock-modal.component.html",
  styleUrls: ["./stock-modal.component.scss"]
})
export class StockModalComponent implements OnInit {
  stock: any = {
    mode: "0",
    id_produit: "1",
    quantite_stock: 0,
    date_peremption_stock: 0,
    prix_unitaire_stock: 1,
    prix_total_stock: 1,
    original_stock: 0,
    id_fournisseur: "0",
    nom_fournisseur: "0"
  };
  fournisseurs = [];
  ets = JSON.parse(LocalStorage.getItem("currentEts"));

  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<StockModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data.mode === "0") {
      this.stock.mode = data.mode;
      this.stock.id_produit = data.id_produit;
    } else {
      this.stock = data;
      this.stock.original_stock = data.quantite_stock;
    }
  }

  ngOnInit() {
    this.service
      .get(ENDPOINTS.get_fournisseurs_ets, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.fournisseurs = data.body;
      });
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }

    var tmp_stock = this.stock;
    if (parseInt(tmp_stock.quantite_stock) < 0) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Inserez un quantite > 0"
      });
      return;
    }
    if (parseInt(tmp_stock.prix_unitaire_stock) < 0) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Inserez un prix > 0"
      });
      return;
    }
    console.log(tmp_stock);
    // tmp_stock.id_produit = this.stock.id_produit;
    tmp_stock.id_etablissement = this.ets.id_etablissement;
    // tmp_stock.mode = this.stock.mode;
    var index = this.fournisseurs.findIndex(
      item => item.id_fournisseur == tmp_stock.id_fournisseur
    );
    tmp_stock.nom_fournisseur = this.fournisseurs[index].nom_fournisseur;
    switch (tmp_stock.mode) {
      case "0":
        if (tmp_stock.date_peremption_stock != 0) {
          tmp_stock.date_peremption_stock = new Date(
            tmp_stock.date_peremption_stock
          ).getTime();
        }

        tmp_stock.date_ajout_stock = new Date().getTime();
        tmp_stock.prix_total_stock =
          parseInt(tmp_stock.quantite_stock) *
          parseInt(tmp_stock.prix_unitaire_stock);
        console.log(tmp_stock);
        // return;
        delete tmp_stock.mode;
        delete tmp_stock.original_stock;
        Loader.load();
        this.service
          .post(ENDPOINTS.post_add_stock, tmp_stock)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        if (tmp_stock.date_peremption_stock != 0) {
          tmp_stock.date_peremption_stock = new Date(
            tmp_stock.date_peremption_stock
          ).getTime();
        }
        tmp_stock.date_ajout_stock = new Date().getTime();
        tmp_stock.prix_total_stock =
          parseInt(tmp_stock.quantite_stock) *
          parseInt(tmp_stock.prix_unitaire_stock);
        console.log(tmp_stock);
        // return;
        delete tmp_stock.mode;
        Loader.load();
        this.service
          .post(ENDPOINTS.post_update_stock, tmp_stock)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      default:
        break;
    }
  }
}
