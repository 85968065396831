import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-edit-modal',
  templateUrl: './user-edit-modal.component.html',
  styleUrls: ['./user-edit-modal.component.scss']
})
export class UserEditModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
