import { Component, OnInit, Inject } from "@angular/core";
import { CorePrototype } from "../../../core/services/core.prototype";
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA, MdcSelectChange } from '@angular-mdc/web';
import { ENDPOINTS } from "../../model/endpoints";
import { LocalStorage } from "../../helpers/localSortage";
import { NgForm } from "@angular/forms";
import { DialogBox } from "../../helpers/dialogBox";
import { Loader } from "../../helpers/loader";
import { User } from '../../helpers/user';

@Component({
  selector: "app-sortie-produit-modal",
  templateUrl: "./sortie-produit-modal.component.html",
  styleUrls: ["./sortie-produit-modal.component.scss"]
})
export class SortieProduitModalComponent implements OnInit {
  sortie = {
    id_sortie_produit: "",
    id_produit: "",
    id_categorie_sortie: "",
    id_admin: User.getUserId(),
    libelle_sortie_produit: "",
    description_sortie_produit: "",
    date_sorties: new Date(),
    date_sortie_produit: 0,
    quantite_sortie_produit: 1,
    prix_unitaire_produit: 1,
    prix_total_produit: 1,
    id_etablissement: 1,
    mode: 0
  };
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  categories_sorties = [];
  produits = [];
  employes = [];
  actual_produit = null;
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public dialogRef: MdcDialogRef<SortieProduitModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    if (data !== null) {
      if (data.mode === 1) {
        data.date_sorties = new Date(
          parseInt(data.date_sortie_produit)
        ).getTime();
        this.sortie = data;
      } else {
      }
    }
  }

  ngOnInit() {
    this.sortie.id_etablissement = this.ets.id_etablissement;
    this.service
      .get(ENDPOINTS.get_categories_sortie, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.categories_sorties = data.body;
      });
    this.service
      .get(ENDPOINTS.get_min_product_institute, {
        id_etablissement: this.ets.id_etablissement
      })
      .then((data: any) => {
        console.log(data);
        this.produits = data.body.produits;
      });
    // this.service
    //   .get(ENDPOINTS.get_institute_employees, {
    //     id_etablissement: this.ets.id_etablissement
    //   })
    //   .then((data: any) => {
    //     this.employes = data.body;
    //     console.log(data);
    //   });
  }
  select_product(event: MdcSelectChange) {
    if (event.source.ngControl.control.touched == false) {
    } else {
      var index = this.produits.findIndex((item) => { return item.id_produit == event.value })

      if (event.index != -1) {
        this.sortie.prix_unitaire_produit = this.produits[event.index - 1].prix_produit_classic;
        this.actual_produit = this.produits[event.index - 1];
      } else {
        // this.not_choosen = true;
      }
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs"
      });
      return;
    }
    var tmp_prod = Object.assign({}, this.sortie);
    // tmp_prod.date_sortie_produit = tmp_prod.date_sorties.getTime();
    if (typeof tmp_prod.date_sortie_produit === "object") {
      tmp_prod.date_sortie_produit = tmp_prod.date_sorties.getTime();
    } else {
      tmp_prod.date_sortie_produit = new Date(tmp_prod.date_sorties).getTime();
    }
    delete tmp_prod.date_sorties;
    // delete tmp_prod.mode;
    tmp_prod.id_etablissement = this.ets.id_etablissement;
    tmp_prod.prix_total_produit =
      tmp_prod.prix_unitaire_produit * tmp_prod.quantite_sortie_produit;

    Loader.load();
    switch (tmp_prod.mode) {
      case 0:
        delete tmp_prod.mode;
        delete tmp_prod.id_sortie_produit;
        // <any> tmp_prod.date_sortie;
        tmp_prod.libelle_sortie_produit = tmp_prod.libelle_sortie_produit + '( ' + this.actual_produit.nom_produit + ' )';
        this.service
          .post(ENDPOINTS.post_sortie_produit, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;
      case 1:
        delete tmp_prod.mode;

        this.service
          .post(ENDPOINTS.post_update_sortie_produit, tmp_prod)
          .then((data: any) => {
            console.log(data);
            Loader.stopLoading();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        break;
    }
  }
}
