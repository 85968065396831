import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Component, OnInit, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { CorePrototype } from "src/app/core/services/core.prototype";
import { DialogBox } from "src/app/shared/helpers/dialogBox";
import { ImageUtils } from "src/app/shared/helpers/imageUtils";
import { Loader } from "src/app/shared/helpers/loader";
import { User } from "src/app/shared/helpers/user";
import { ENDPOINTS } from "src/app/shared/model/endpoints";

@Component({
  selector: "app-ets-modal",
  templateUrl: "./ets-modal.component.html",
  styleUrls: ["./ets-modal.component.scss"],
})
export class EtsModalComponent implements OnInit {
  ets = {
    nom_etablissement: "",
    logo_etablissement: null,
    email_etablissement: "",
    telephone_etablissement_1: "",
    telephone_etablissement_2: "",
    mode: "0",
    description_etablissement: "",
    localisation_etablissement: "",
    id_utilisateur: "",
    id_etablissement: "",
    pay_in_advance: 0,
    auto_validation_order: 0,
    can_kitchen_handle_stock: 0,
    pay_in_advance_bool: false,
    auto_validation_order_bool: false,
    can_kitchen_handle_stock_bool: false,
    ets_level: 2,
  };
  fileLogo = null;

  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MdcDialogRef<EtsModalComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data.mode === "0") {
      this.ets.mode = data.mode;
    } else {
      if (data.pay_in_advance == "1") {
        data.pay_in_advance_bool = true;
      } else {
        data.pay_in_advance_bool = false;
      }
      if (data.auto_validation_order == "1") {
        data.auto_validation_order_bool = true;
      } else {
        data.auto_validation_order_bool = false;
      }
      if (data.can_kitchen_handle_stock == "1") {
        data.can_kitchen_handle_stock_bool = true;
      } else {
        data.can_kitchen_handle_stock_bool = false;
      }

      this.ets = data;
    }
  }

  ngOnInit() { }

  onchangeLogo(event) {
    if (ImageUtils.isImage(event.target.files[0])) {
      if (ImageUtils.getFileSize(event.target.files[0]) < 1024 * 1024) {
        this.fileLogo = event.target.files[0];
        this.ets.logo_etablissement = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.fileLogo)
        );
      } else {
        DialogBox.showMessage(this.dialog, {
          title: "Message",
          body: "Votre image doit peser moins d'un mega",
        });
      }
    } else {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez inserer un fichier qui est une image image",
      });
    }
  }
  submit(form: NgForm) {
    if (!form.valid) {
      DialogBox.showMessage(this.dialog, {
        title: "Message",
        body: "Veuillez remplir tous les champs",
      });
      return;
    }
    var tmp_ets = Object.assign(this.ets, {});
    tmp_ets.id_utilisateur = User.getUserId();
    // tmp_ets.type_account = 2;
    if (tmp_ets.pay_in_advance_bool == true) {
      tmp_ets.pay_in_advance = 1;
    } else {
      tmp_ets.pay_in_advance = 0;
    }
    // if (tmp_ets.auto_validation_order_bool == true) {
    tmp_ets.auto_validation_order = 1;
    // } else {
    //   tmp_ets.auto_validation_order = 0;
    // }
    // if (tmp_ets.can_kitchen_handle_stock_bool == true) {
    tmp_ets.can_kitchen_handle_stock = 1;
    // } else {
    //   tmp_ets.can_kitchen_handle_stock = 0;
    // }

    console.log(tmp_ets);
    switch (tmp_ets.mode) {
      case "0":
        if (this.fileLogo !== null) {
          tmp_ets.logo_etablissement = this.fileLogo;
        } else {
          // delete cat.couverture_profil;
          DialogBox.showMessage(this.dialog, {
            title: "Message",
            body: "Veuillez inserer une image pour créer un etablissement",
          });
          return;
        }
        delete tmp_ets.mode;
        delete tmp_ets.id_etablissement;
        delete tmp_ets.pay_in_advance_bool;
        delete tmp_ets.auto_validation_order_bool;
        delete tmp_ets.can_kitchen_handle_stock_bool;
        // delete tmp_prod.id_type_produit;
        Loader.loadBack();

        this.service
          .post(ENDPOINTS.post_institute, tmp_ets)
          .then((data: any) => {
            console.log(data);
            Loader.stoploadingBack();
            this.dialogRef.close(data.body);
          });
        break;
      case "1":
        if (this.fileLogo !== null) {
          tmp_ets.logo_etablissement = this.fileLogo;
        }
        delete tmp_ets.mode;
        // delete tmp_ets.categories;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        // delete tmp_prod.mode;
        var final = {
          id_etablissement: tmp_ets.id_etablissement,
          logo_etablissement: tmp_ets.logo_etablissement,
          description_etablissement: tmp_ets.description_etablissement,
          telephone_etablissement_1: tmp_ets.telephone_etablissement_1,
          localisation_etablissement: tmp_ets.localisation_etablissement,
          email_etablissement: tmp_ets.email_etablissement,
          nom_etablissement: tmp_ets.nom_etablissement,
          pay_in_advance: tmp_ets.pay_in_advance,
        };
        Loader.loadBack();

        this.service
          .post(ENDPOINTS.post_update_institute, final)
          .then((data: any) => {
            console.log(data);
            Loader.stoploadingBack();
            this.dialogRef.close(data.body);
          });
        break;

      default:
        this.dialogRef.close(null);
        break;
    }
  }
}
