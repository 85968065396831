import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  AfterViewInit,
} from "@angular/core";
import { User } from "../../helpers/user";
import { LocalStorage } from "../../helpers/localSortage";
import { MessagingService } from "../../services/messaging.service";
import { CorePrototype } from "../../../core/services/core.prototype";
import { ENDPOINTS } from "../../model/endpoints";
import { UpdatePassComponent } from "../update-pass/update-pass.component";
import { MdcDialog } from "@angular-mdc/web";
import { CommandeDetailModalComponent } from "../commande-detail-modal/commande-detail-modal.component";
import { Router } from "@angular/router";
import { StockGeneralModalComponent } from "../stock-general-modal/stock-general-modal.component";
declare var jQuery: any;
@Component({
  selector: "header-component",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * @var Boolean tell is wheather or not the global loader should be launch
   */
  @Input("loading") loading;

  /**
   * @var Boolean tell if a user is authenticate or not
   */
  isLogin: Boolean = false;
  open: Boolean = false;
  token: any = null;
  messages: any[] = [];
  destinations = [
    {
      title: "Ventes",
      menus: [
        {
          label: "Tableau de bord",
          icon: "mdiMonitorDashboard",
          route: "sales/home",
          index: 2,
        },
        { label: "Factures", icon: "mdiCreditCardOutline", route: "sales/commandes", index: 4 },
        {
          label: "Factures proforma",
          icon: "mdiNewspaper",
          route: "sales/proforma",
          index: 28,
        },
        {
          label: "Declarer les ventes",
          icon: "mdiCartPlus",
          route: "sales/facturation",
          index: 78,
        },
        {
          label: "Ventes",
          icon: "mdiSack",
          route: "sales/ventes",
          index: 77,
        },
        {
          label: "Recettes",
          icon: "mdiCash",
          route: "sales/recettes",
          index: 19,
        }

      ]
    },
    {
      title: "Gestion des stocks",
      menus: [
        {
          label: "Gerer les fournisseur",
          icon: "mdiBriefcasePlus",
          route: "stocks/fournisseurs",
          index: 34,
        },
        { label: "Articles et produits", icon: "mdiBriefcaseVariant", route: "stocks/articles", index: 6 },
        {
          label: "Approvisionnement / Magasin",
          icon: "mdiBriefcaseDownloadOutline",
          route: "stocks/stocks",
          index: 35,
        },
        {
          label: "Exposition / Boutique",
          icon: "mdiStore",
          route: "stocks/expositions",
          index: 41,
        },
        {
          label: "Inventaire",
          icon: "mdiClipboardList",
          route: "stocks/inventaire",
          index: 42,
        },
        {
          label: "Inventaire Jour.",
          icon: "mdiClipboardCheckOutline",
          route: "stocks/inventaire_jour",
          index: 43,
        },
        {
          label: "Exercice",
          icon: "mdiCalendarSync",
          route: "gestion-ets/exercice",
          index: 44,
        },
        {
          label: "Mouvement de stock",
          icon: "mdiCogSyncOutline",
          route: "stocks/mouvements",
          index: 36,
        },
        {
          label: "Retours en stock",
          icon: "mdiArrowDownBoldBoxOutline",
          route: "stocks/retours",
          index: 37,
        },
        {
          label: "Bon de livraison",
          icon: "mdiTicket",
          route: "stocks/bon_livraisons",
          index: 38,
        },
      ]
    },
    {
      title: "Gestion de l'etablissement",
      menus: [
        { label: "Départements", icon: "mdiGoogleClassroom", route: "gestion-ets/dep", index: 3 },
        { label: "Catégories", icon: "mdiShape", route: "gestion-ets/categories", index: 7 },
        { label: "Clients", icon: "mdiBadgeAccount", route: "gestion-ets/clients", index: 80 },
        { label: "Employés", icon: "mdiAccountHardHat", route: "gestion-ets/employes", index: 5 },
        {
          label: "Bulk upload",
          icon: "mdiFolderPlus",
          route: "gestion-ets/bulk_upload",
          index: 8,
        },
        {
          label: "Options de livraisons",
          icon: "mdiTruck",
          route: "gestion-ets/livraison",
          index: 102,
        },
      ]
    },

    {
      title: "Dépenses",
      menus: [

        {
          label: "Config sorties",
          icon: "mdiClipboardFlow",
          route: "gestion-ets/sorties",
          index: 9,
        },
        {
          label: "Declarer des pertes",
          icon: "mdiClipboardFlowOutline",
          route: "gestion-ets/sorties_all",
          index: 10,
        },
      ]
    },
    {
      title: "Paramétres",
      menus: [
        {
          label: "Mon Etablissement",
          icon: "mdiDomain",
          route: "configurations/settings",
          index: 11,
        },
        {
          label: "Paramétrage",
          icon: "mdiCogBox",
          route: "configurations/params",
          tag: "Prestige",
          index: 33,
        },
      ]
    },

  ];
  // submissionMenu = [

  //   {
  //     label: "Paramétrage",
  //     icon: "mdiCogBox",
  //     route: "params",
  //     tag: "Prestige",
  //     sub: [],
  //   },
  // ];
  user = User.getUser();
  ets = JSON.parse(LocalStorage.getItem("currentEts"));
  constructor(
    private service: CorePrototype,
    public dialog: MdcDialog,
    private router: Router
  ) { }

  ngOnInit() {
    jQuery("#perso-backdrop").hide();
    this.hideMenu();
    console.log(this.ets);
  }
  ngAfterViewInit() {
    var that = this;

    var div = document.getElementById("perso-backdrop");
    // tslint:disable-next-line:only-arrow-functions
    div.addEventListener("click", function () {
      that.openMenu();
    });
    this.destinations.forEach((elementMenu: any) => {
      // console.log(element.route);
      elementMenu.menus.forEach(element => {
        if ("/" + element.route === this.router.url) {
          jQuery("." + element.route).addClass("mdc-list-item--activated");
          // console.log('#mdc-list-item-' + element.index);
        }
      });

    });
    // if (this.ets.has_global_config === 1) {
    // } else {
    //   this.destinations.splice(8, 1);
    // }
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // if (this.ets.has_global_config === 1) {
    // } else {
    //   this.destinations.splice(8, 1);
    // }
  }
  logout() {
    LocalStorage.delete("Squares_user");
    location.reload();
  }
  updatePass() {
    const dialogRef = this.dialog.open(UpdatePassComponent);

    dialogRef.afterClosed().subscribe((resultModal) => {
      console.log(resultModal);
      if (resultModal !== "close" || resultModal !== null) {
        // this.finishUpdate(resultModal);
      }
    });
  }
  openModal(param) {
    if (param === "all") {

    } else if (param.data.body.title === "100") {
      const dialogRef = this.dialog.open(CommandeDetailModalComponent, {
        data: param.data.body,
      });

      dialogRef.afterClosed().subscribe((resultModal) => {
        console.log(resultModal);
        if (resultModal !== "close" || resultModal !== null) {
          // this.finishUpdate(resultModal);
        }
      });
    } else {

    }
  }
  openMenu() {
    if (this.open === false) {
      this.open = true;
      this.showMenu();
    } else {
      this.open = false;
      // jQuery(".menutext").hide();
      this.hideMenu();
    }
  }
  showMenu() {
    jQuery("#menu").css({
      transform: "translateX(0%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "1",
      display: "block",
    });
  }
  hideMenu() {
    jQuery("#menu").css({
      transform: "translateX(-100%)",
    });
    jQuery("#perso-backdrop").css({
      opacity: "0",
      display: "none",
    });
  }
  goToMenu(fragment) {
    document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
  }
  routeMenu(item) {
    console.log(item);
    var dialogRef = null;
    switch (item.route) {
      case "add_stock":
        dialogRef = this.dialog.open(StockGeneralModalComponent, {
          data: { mode: "0" },
        });

        dialogRef.afterClosed().subscribe((resultModal: any) => {
          console.log(resultModal);
          // if (resultModal !== "close" || resultModal !== null) {
          //   if (resultModal.id_etablissement) {
          //     this.entreprises.push(resultModal);
          //     return;
          //   }
          // }
        });
        break;
      case "declare_loss":
        this.router.navigateByUrl("sorties_all");
        break;
      case "add_supplier":
        this.router.navigateByUrl("fournisseurs");

        // dialogRef = this.dialog.open(FournisseurModalComponent, {
        //   data: { mode: "0" }
        // });

        // dialogRef.afterClosed().subscribe((resultModal: any) => {
        //   console.log(resultModal);
        //   // if (resultModal !== "close" || resultModal !== null) {
        //   //   if (resultModal.id_etablissement) {
        //   //     this.entreprises.push(resultModal);
        //   //     return;
        //   //   }
        //   // }
        // });
        break;
      case "facturation":
        this.router.navigateByUrl("facturation");

        break;
      case "report":
        this.router.navigateByUrl("rapport");

        break;

      default:
        break;
    }
  }
}
