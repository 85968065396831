export const codes = {
  "100": "Succès",
  "101": "Erreur interne au serveur",
  "102": "Service indisponible",
  "104": "Utilisateur bloqué",
  "105": "Compte inexistant",
  "106": "Déjà connecter",
  "107": "Opération en cours de traitement",
  "108": "Mot de passe incorrect",
  "109": "Délégué inexistant",
  "110": "Tentative d’ajouter une information qui existe déjà",
  "111": "Compte commission vide",
  "112": "Montant supérieur au solde du compte commission",
  "113": "Compte insuffisant",
  "114": "Utilisateur deconnecter",
  "115": "Echec de l’opération",
  "116": "Transaction inexistante",
  "117": "Echec",
};

export const STATE = {
  "1": "Nouveau",
  "2": "Validé par la cuisine",
  "3": "Rejete par la cuisine",
  "4": "Payé",
  "5": "Annulé par le client",
  "6": "",
  "7": "Pret",
  "8": "Réception éffective",
  "9": "Réception inéffective",
  "10": "Devis de livraison",
};
export const STATE_PACKET = {
  "1": "Nouveau",
  "2": "Validé par le client",
  "3": "En cours d'execution",
  "4": "Annulé par le client",
  "5": "Rejété par teslim",
  "6": "En cours d'acheminement",
  "7": "Paquet acheminé",
  "8": "Réception éffective par le client",
  "9": "Réception inéffective",
  "10": "Devis de livraison",
};

export const MOUVEMENT_STOCKS = {
  "1": "Achat des stocks",
  "2": "Vente d'un produit",
  "3": "Entrée d'un bon de livraison",
  "4": "Sortie d'un bon de livraison",
  "5": "Perte d'un produit",
  "6": "Retour en stock d'un produit",
  "7": "Déplacement en éxposition",
  "8": "Modification d'un stock enregistré",
  "9": "Modification d'un produit dans une commande",
  "10": "Supression d'un produit dans une commande",
  "11": "Resitution apres annulation de la commande",
  "12": "Modif. d'un stock en exposition",
  "13": "Modif. des bons de livraion en entrée",
  "14": "Modif. des bons de livraion en sortie",
  "15": "Modif. d'une perte",
  "16": "Déstockage package produit",
  "17": "Mise a jour des stock de l'exercice précédent",
  "18": "Approv. a partir de l'exercice précédent",
  "19": "Vente d'un produit par l'administration",
  "20": "Expo. a partir de l'exercice précédent",
}
export const UNITE_CRITERES = [{ label: "Hertz", val: "1" }, { label: "Giga octet", val: "2" }];
export const BASE_URL = "https://www.squares.seeds.cm/";
export const BASE_URL_DEPLOY = "https://www.squares.seeds.cm/";

// export const BASE_URL = "https://www.test.squares.cm/";
// export const BASE_URL_DEPLOY = "https://www.test.squares.cm/";
export const LOGO_URL = "https://www.adminsquares.seeds.cm/assets/logo.png";
// export const BASE_URL = "http://localhost:4200";
